export default {
    unauthorized_access_title: "Accès non autorisé",
    you_are_not_authorized_to_access:
        "Vous n'avez pas le droit d'accèder à cette ressource.",
    network_error: "Erreur du réseau",
    network_error_text:
        "Impossible de se connecter au serveur, vérifiez votre connexion Internet ou réessayez plus tard.",
    error_alert_title: "Une erreur est survenue",
    error_alert_text:
        "Une erreur inattendue s'est produite lors de votre demande. Veuillez réessayer plus tard.",
    token_expired_alert_title: "Session expirée",
    token_expired_alert_text:
        "Vote session a expiré. Veuillez vous re-connecter SVP...",
    service_error_text:
        "Le service est temporairement indisponible. Veuillez réessayer ultérieurement.",
    Copyright: "Tous droits réservés",
    administration: "Administration",
    ok: "Ok",
    my_novy_account: "Mon compte Novy",
    logout: "Se déconnecter",
    no_notifications: "Pas de notifications",
    operational_view: "Vue Opérationnelle",
    sort_by: "Trier par",
    sorted_by: "Trié par",
    projects_count_suffix: "Projets",
    select_interval: "Selectionner un intervalle",
    cancel: "Annuler",
    confirm: "Confirmer",
    enregistrement: "Enregistrement",
    take_snapshot: "Figer le dashboard",
    save_dashboard_indicators_question:
        "Voulez-vous vraiment figer les indicateurs de ce Dashboard ?",
    dashboard_fige_success: "Votre Dashboard a été figé avec succès",
    dashboard_fige: "Dashboard Figé",
    search_placeholder: "Recherche",
    modification: "Modification",
    edit_dashboard_indicators_question:
        "Voulez-vous vraiement modifier les indicateurs de ce Dashboard ?",
    dashboard_modifie_success: "Votre Dashboard a été modifié avec succès",
    annulation: "Annulation",
    cancel_edit_dashboard_indicators_question:
        "Voulez-vous vraiment annuler les modifications de ce Dashboard",
    dashboard_modifie: "Dashboard Modifié",
    raf: "RAF",
    date: "Date",
    risques: "Risques",
    actions: "Actions",
    en_cours_status: "En cours",
    termine_status: "Terminé",
    forfait: "Forfait",
    regie: "Régie",
    project_col: "Projet",
    ch_vendue_col: "Ch. vendue",
    ch_consommee_col: "Ch. consommée",
    raf_col: "RAF",
    risque_col: "Risque",
    type_col: "Type",
    status_col: "Statut",
    projects_list_page_title: "Vue d'ensemble des projets",
    filter_all_choice: "Tous",
    suppression: "Suppression",
    notes: "Notes",
    delete: "Supprimer",
    save: "Enregistrer",
    validate: "Valider",
    yes_save: "Oui, enregistrer",
    saving: "Enregistrement",
    yes: "Oui",
    no: "Non",
    edit: "Modifier",
    project_indicators: "Indicateurs de projet",
    data_history: "Historique des données",
    save_quick_edit: "Enregistrer la modification",
    quick_edit: "Modification rapide",
    export: "Exporter",
    synthese: "Synthèse",
    historique: "Historique",
    projects_list: "Liste des projets",
    project: "Projet",
    projects: "Projets",
    project_type: "Type de projet",
    project_types: "Types de projets",
    project_status: "Statuts de projets",
    create_success: "Création effectuée avec succès",
    update_success: "Modification effectuée avec succès",
    delete_success: "Suppression effectuée avec succès",
    clone_success: "Clonage effectué avec succès",
    create_error: "Création a échoué",
    update_error: "Modification a échoué",
    delete_error: "Suppression a échoué",
    clone_error: "Clonage a échoué",
    ressource_in_use_error: "Ressource utilisé",
    no_data: "Aucun enregistrement trouvé",
    risque: "Risque",
    no_project_found: "Aucun projet trouvé",
    no_project_type_found: "Aucun type de projet trouvé",
    pagination_of: "sur",
    update: "Mettre à jour",
    reprendre: "Reprendre",
    resume_live_dashboard: "Quitter l'historique",
    snapshot: "Snapshot",
    rtables: "Tables de référence",
    parameters: "Paramètres",
    project_risk: "Risque de projet",
    project_construction: "Construction de projet",
    project_perimetre: "Périmètre de projet",
    project_planning: "Planning de projet",
    project_staffing: "Staffing de projet",
    action_priority: "Priorité d'action",
    action_status: "Statut d'action",
    label: "Libellé",
    threshold_min: "Limite min.",
    level: "Niveau",
    description: "Description",
    name: "Nom",
    value: "Valeur",
    add: "Ajouter",
    confirm_delete: "Êtes-vous sûr(e) de vouloir supprimer",
    confirm_delete_type: "Êtes-vous sûr(e) de vouloir supprimer le type",
    confirm_delete_rate: "Êtes-vous sûr(e) de vouloir supprimer le taux",
    confirm_delete_currency: "Êtes-vous sûr(e) de vouloir supprimer la devise",
    taxes: "Taxes",
    currency: "Devise",
    no_currency_found: "Aucune devise trouvée",
    tvaRate: "TVA",
    tax_types_list: "Liste des types de taxe",
    tax_rates_list_for: "Liste des taux de {{typeLabel}}",
    start: "Début",
    end: "Fin",
    client_col: "Client",
    no_client_found: "Aucun client trouvé",
    include_sub_projects_switch_title: "Inclure les sous projets",
    client: "Client",
    clients: "Clients",
    assign_client: "Attacher un client",
    notify_actions: "Envoyer les rappels",
    language_not_yet_supported: "La langue {{lang}} n'est pas encore supportée",
    parent_project: "Projet parent",
    last_activity: "Dernière activité",
    affect_in_novy: "Affecter sur Novy",
    export_as_pdf: "Exporter en PDF",
    screen_size_not_supported_title: "Résolution Non Supportée",
    screen_size_not_supported_description1:
        "La taille de votre écran n'est pas supportée.",
    screen_size_not_supported_description2:
        "Veuillez ouvrir la page dans un écran large (≥960px).",
    open: "Ouvrir",
    clear: "Effacer",
    close: "Fermer",
    variables: "Variables",
    default_value: "Valeur par défaut",
    number: "Numéro",
    number2: "Nombre",
    percent: "Pourcentage",
    view_as_pdf: "Consulter en PDF",
    today: "Aujourd'hui",
    pdf_not_available: "PDF non disponible",
    add_to_favorites: "Ajouter aux favoris",
    remove_from_favorites: "Supprimer des favoris",
    automated_snapshot: "Figé automatiquement",
    page: "Page",
    first_page: "Première page",
    last_page: "Dernière page",
    previous_page: "Page précédente",
    next_page: "Page suivante",
    labelRowsPerPage: "Lignes par page:",
    January: "Janvier",
    February: "Février",
    March: "Mars",
    April: "Avril",
    May: "Mai",
    June: "Juin",
    July: "Juillet",
    August: "Août",
    September: "Septembre",
    October: "Octobre",
    November: "Novembre",
    December: "Décembre",
    january: "janvier",
    february: "février",
    march: "mars",
    april: "avril",
    may: "mai",
    june: "juin",
    july: "juillet",
    august: "août",
    september: "septembre",
    october: "octobre",
    november: "novembre",
    december: "décembre",
    grid_view: "Vue en grille",
    list_view: "Vue en liste",
    view_all: "Voir tous",
    view_favorites: "Voir mes favoris",
    show_more: "Afficher Plus",
    all: "Tous",
    favorites: "Favoris",
    my_favorites: "Mes favoris",
    alpha_version:
        "Cette version est encore en période d'essai et vise à tester l'expérience utilisateur. Une amélioration supplémentaire peut impliquer des changements majeurs de la fonctionnalité ou une réinitialisation des données.",
    responsable: "Responsable",
    reset: "Réinitialiser",
    drag_and_drop_file: "Glissez et déposez votre fichier ici",
    or: "Ou",
    browse: "Parcourir",
    kilo_byte_abbr: "ko",
    no_file_selected: "Aucun fichier choisi",
    processing: "Traitement en cours",
    import: "Importer",
    header_row: "Ligne de l'entête",
    first_data_row: "1ère ligne de données",
    rate: "Taux",
    invalid_file_or_misconfiguration_error:
        "Une erreur est servenue. Veuillez vérifier votre fichier et les paramètres du chargement puis réessayer.",
    download_template: "Télécharger le modèle de base",
    template: "Modèle",
    invoicing: "Facturation",
    invoice: "Facture",
    invoices: "Factures",
    risk0: "Faible",
    risk1: "Moyen",
    risk2: "Élevé",
    his_date_col: "Date",
    his_periode_col: "Période",
    his_charge_vendue_col: "Charge vendue",
    his_charge_consommee_col: "Charge consommée",
    his_raf_col: "RAF",
    his_risque_col: "Risque",
    his_actions_col: "Actions",
    collaborator: "Collaborateur",
    no_collaborator_found: "Aucun collaborateur trouvé",
    multiple_cloning: "Clonage multiple",
    list_invoices_to_clone: "Liste des factures à cloner",
    cloned_invoices_recap: "Récapitulatif des factures clonées",
    cava_mens_col: "CAVA Mensuel",
    mbth_mens_euro_col: "MBth. € mens",
    mbth_mens_percent_col: "MBth. % mens",
    cava_cum_col: "CAVA cumulé",
    mbth_cum_euro_col: "MBth. € cum",
    cava_mensuel: "CAVA Mensuel",
    cava_cumule: "CAVA Cumulé",
    mbth_mensuel: "MBth Mensuel",
    mbth_cumule: "MBth Cumulé",
    mbth_mensuel_especes: "MBth. € mensuel",
    month: "Mois",
    caht_billed: "CA HT facturé",
    ca_provisional: "CA prévisionnel",
    ca_awaiting_payment: "CA en attente de paiement",
    cumulated: "Cumulé",
    add_invoice: "Ajouter une facture",
    invoice_number: "Numéro",
    amount_excl_tax: "Montant HT",
    total_excl_tax: "Total HT",
    converted_amount: "Montant converti",
    unit_price: "Prix unitaire",
    issue_date: "Date d'émission",
    expected_date: "Date prévisionnelle",
    real_date: "Date de règlement",
    settlement_date: "Date de règlement",
    expected_settlement_date: "Date limite de paiement",
    status: "Statut",
    download: "Télécharger",
    all_clients: "Tous les clients",
    new_invoice: "Nouvelle facture",
    invoice_general_info: "Informations générales",
    invoice_label: "Libellé de la facture",
    invoice_details: "Détails de la facture",
    order_reference: "Référence de commande",
    reference: "Référence",
    quantity: "Quantité",
    amount_incl_tax: "Montant TTC",
    invoice_form_title: "Edition de facture",
    invoice_pdf_title: "Facture PDF",
    add_order_item_label: "Ajouter une ligne",
    total_amount_ttc: "Montant total TTC",
    total_amount_ht: "Montant total HT",
    payed_amount_ttc: "Payé",
    payed_total_ttc: "Total payé TTC",
    payments_count: "Nombre de paiements",
    remaining_amount_ttc: "Restant",
    montant_ca_ht_facture: "Montant CA HT facturé",
    montant_ca_ht_a_facturer: "Montant CA HT à facturer",
    montant_ca_ht_facture_en_attente: "Montant CA HT facturé en attente de règlement",
    back_to_annual_view: "Revenir à la vue annuelle",
    order_items: "Articles de la commande",
    issueDate: "Date d'émission",
    confirm_invoice_status_change: "Le statut de la facture changera de {{oldStatus}} à {{newStatus}}, voulez-vous continuez?",
    confirm_invoice_save: "Le statut de la facture est {{status}}, voulez-vous continuez?",
    confirm_delete_invoice: "Êtes-vous sûr(e) de vouloir supprimer la facture ?",
    confirm_delete_multiple_invoices: "Êtes-vous sûr(e) de vouloir supprimer {{count}} factures?",
    back_to_monthly_view: "Revenir à la vue mensuelle",
    invoice_audit_title: "Historique de la facture",
    show_audit: "Afficher l'historique",
    invoice_search_placeholder: "Recherche de factures par numéro, libellé, référence ou montant restant TTC",
    no_invoice: "Aucune facture",
    monthly_summary: "Synthèse des mois",
    invoices_list: "Liste des factures",
    invoicing_entity: "Entité à facturer",
    invoicing_entities: "Entités à facturer",
    address: "Adresse",
    postal_code: "Code postal",
    city: "Ville",
    country: "Pays",
    code: "Code",
    vat_number: "Numéro TVA",
    all_year: "Toute l'année",
    no_entity_found: "Aucune entité trouvée",
    no_tax_value: "Aucune",
    currencies: "Devises",
    required: "requis",
    PREVISIONNELLE: "Prévisionnelle",
    A_VENIR: "A venir",
    EMISE: "Emise",
    PARTIELLEMENT_REGLEE: "Partiellement réglée",
    REGLEE: "Réglée",
    AVOIREE: "Avoirée",
    avoir: "Avoir",
    /* BEGIN audit json */
    amountExclTax: "Montant HT",
    amountInclTax: "Montant TTC",
    realDate: "Date de règlement", // Do not remove, it is used for old audit
    settlementDate: "Date de règlement",
    expectedDate: "Date prévisionnelle",
    expectedSettlementDate: "Date limite de paiement",
    invoicingEntity: "Entité à facturer",
    triggerAction: "Déclenchement",
    orderReference: "Référence de commande",
    /* END audit json */

    invoice_different_entity: "Facturer une entité différente",
    invoice_currency: "Devise de la facture",
    trigger_action: "Déclenchement",
    invoice_trigger_action: "Déclenchement de la facture",

    create_non_existant_clients: "Créer les clients qui n'existent pas",
    create_non_existant_tax_rates: "Créer les taux des taxes qui n'existent pas",
    included_tax_types: "Types de taxes inclus",
    create_non_existant_invoice_entities: "Créer les entités à facturer qui n'existent pas",
    amount: "Montant",
    invoice_fields: 'Champs de facture',
    corresponding_column_in_file: 'La colonne correspondante dans le fichier',
    corresponding_column_in_file_tooltip_text: "Les noms des colonnes ne sont pas sensibles à la casse",
    import_invoices_created_success: "L'import est effectué avec succès. {{nbr}} factures ont été créées.",
    create_invoice_title: 'Créer une facture',
    import_invoice_title: 'Importer des factures',
    show_errors: "Afficher",
    hide_errors: "Masquer",
    import_failed_unknown_error_occured: "L'import a échoué. Une erreur inconnue est survenue.",
    unknown_error_occured: "Une erreur inconnue est survenue.",
    unknown: "inconnue",
    import_failed_one_error_occured: "L'import a échoué. 1 erreur est survenue.",
    import_failed_n_errors_occured: "L'import a échoué. {{n}} erreurs sont survenues.",

    invoice_company: "Facturation",
    postalCode: "Code postal",
    tvaNumber: "Numéro de TVA",
    email: "Email",
    siren: "SIREN",
    iban: "IBAN",
    bic: "BIC",
    bankName: "Nom de banque",
    footerMessage: "Note de pied de page",
    duplicate: "Dupliquer",
    settle: 'Passer au statut "Réglée"',
    company_information: "Informations de l'entreprise",
    invoicing_area: "Espace facturation",
    dashboard: "Tableau de bord",
    invoice_management: "Gestion de factures",
    responsable_col: "Responsable",
    digital_signature: "Signature électronique",
    add_document_signature: "Signer un document",
    verify_document_signature: "Vérifier la signature",
    preview_document: "Aperçu du document",
    visible_stamp: "Cachet visible",
    custom_stamp: "Cachet personnalisé",
    upload_stamp: "Charger un cachet",
    sign: "Signer",
    verify: "Vérifier",
    stamp_settings: "Paramétrage du cachet",

    payment_time_averages: "Moyennes des délais de règlement",
    net_turnover: "Chiffre d'affaires net",
    total_net_turnover: "CA. HT net total",
    evolution: "Evolution",
    top_5_clients: "Top 5 clients",
    turnover_evolution: "Evolution du chiffre d'affaires",
    document_signature_success: "La signature du document est authentique.",
    document_signature_error: "La signature du document n'est pas présente ou invalide.",
    document_signature_failed: "La signature du document a échoué.",
    sign_document_success: "La signature éléctronique est ajouté avec succès.",
    sign_document_failed: "Le signature du document a échoué.",
    stamp_size: "Taille du cachet (px)",
    stamp_width: "Largeur du cachet (px)",
    stamp_height: "Hauteur du cachet (px)",

    turnover_evolution_info: "L'évolution de chiffre d'affaire {{totalTurnover}} de la période de {{startMonth}} à {{endMonth}} par rapport à {{prevTotalTurnOver}} de la période de {{prevStartMonth}} à {{prevEndMonth}}.",
    see_more: "Voir plus",
    dates_filter: "Filtrer par dates",
    before_date_chip_title: "avant le {{before}}",
    after_date_chip_title: "après le {{after}}",
    between_date_chip_title: "entre le {{after}} et le {{before}}",
    late_invoices_awaiting_payment: "Factures en retard de paiement",
    delay: "Retard (J)",

    row: "Ligne",
    header: "entête",
    
    "file.corrupted": "Fichier endommagé",
    "unexpected.error": "Erreur inattendue",
    
    mustExistColumnName: "La colonne <u><i>{{first}}</i></u> n'existe pas dans le document",
    mustBeValidColumnName: "La colonne <u><i>{{first}}</i></u> ne correspond pas à une colonne valide dans la configuration",
    
    mustNotAlreadyExistValue: "<u><i>{{first}}</i></u> existe déjà",
    mustBeNullValue: "<u><i>{{first}}</i></u> doit être vide",
    mustNotBeNullValue: "<u><i>{{first}}</i></u> ne doit pas être vide",
    mustNotBeAfterValue: "<u><i>{{first}}</i></u> ne doit pas être après <u><i>{{first}}</i></u>",
    mustBeValidValue: "<u><i>{{first}}</i></u> n'est pas valide",
    mustBeCorrectValue: "<u><i>{{first}}</i></u> erroné(e)",
    mustBeExplicitValue: "<u><i>{{first}}</i></u> ambiguë et doit être explicite",
    mustBeOfTypeValue: "<u><i>{{first}}</i></u> doit être de type <i>{{second}}</i>",

    issueDateBeforeLastEmittedInvoiceIssueDate: "La date d'émission ne doit pas être inférieur à celle de la dernière facture émise (Dernière facture émise numéro {{number}} et date d'émission {{issueDate}})",

    my_settings: "Mes paramètres",
    receive_invoices_issue_reminders: "Recevoir les rappels d'émission des factures",
    receive_invoices_settlement_reminders: "Recevoir les rappels de règlement des factures",
    enable_issue_reminders: "Activer les rappels d'émission",
    disable_issue_reminders: "Désactiver les rappels d'émission",
    enable_settlement_reminders: "Activer les rappels de règlement",
    disable_settlement_reminders: "Désactiver les rapples de règlement",
    issue_reminders_enabled: "Rappels d'émission activés",
    issue_reminders_disabled: "Rappels d'émission désactivés",
    settlement_reminders_enabled: "Rappels de règlement activés",
    settlement_reminders_disabled: "Rappels de règlement désactivés",
    invoice_reminder_days: "Les jours des rappels",
    invoice_reminders: "Rappels des factures",
    payment_delay: "Délai de paiement",
    languages: "Langages",
    invoicing_config: "Paramétrage",
    go_to_invoicing_area: "Voir sur l'espace facturation",
    late_invoices_awaiting_issuing: "Factures en retard d'émission",
    send_reminders_at: "Envoyer les rappels à",
    reminder_time_config_info: "La modification ne sera prise en compte qu'après le prochain envoi",
    issue_reminder_config_text_1: "Envoyer les rappels d'émission à partir de",
    issue_reminder_config_text_2: "jours avant la date prévisionnelle",
    settlement_reminder_config_text_1: "Envoyer les rappels de règlement à partir de",
    settlement_reminder_config_text_2: "jours avant la date de règlement prévisionnelle",
    payment_delay_config_text_1: "Fixer le délai de paiement à",
    payment_delay_config_text_2: "jours après la date d'émission",
    invoice_generation_language_text: "Langage de génération des factures",
    payments: "Paiements",
    invoicePayments : "Paiement",
    add_a_payment : "Ajouter un paiement",
    payments_list: "Liste des paiements",
    select_an_invoice: "Sélectionner une facture",
    select_a_group: "Sélectionner un groupe",

    group: "Groupe",
    exchangeRate: "Taux de change",
    no_invoice_found: "Aucune facture trouvée",
    loading: "Chargement",
    bankingInfoBloc: "Bloc de saisie libre",

    chart_legend_net_turnover: "CA net facturé",
    chart_legend_pending_turnover: "CA en attente de paiement",
    chart_legend_expected_turnover: "CA prévisionnel",
    chart_legend_collected_turnover: "CA encaissé",
    clients_classified_by_turnover: "Clients classés par chiffre d'affaires",
    show_all: "Afficher tout",
    of_which_pending_payment: "{{content}} en attente de paiement",
    total_expected_payment: "{{content}} prévisionnel",
    my_invoices: "Mes factures",
    my_payments: "Mes paiements",
    search: "Recherche",
    
    session_expired_title: "Session expirée",
    session_expired_details: "Vous devez actualiser la page afin de rafraichir votre session, toute saisie sera perdue.",
    refresh: "Actualiser",
    page_not_found: "Page introuvable",
    page_not_found_description: "La page que vous demandez semble introuvable.",

    global_audit: "Audit global",
    rangeStart: "Début",
    rangeFinish: "Fin",
    user: "Utilisateur",
    action: "Action",
    details: "Détails",
    action_details: "Détails de l'action",
    action_CREATE: "Création",
    action_CREATE_ATTACHMENT: "Création attachement: {{name}}",
    action_READ: "Consultation",
    action_READ_ATTACHMENT: "Consultation attachement: {{name}}",
    action_UPDATE: "Modification",
    action_UPDATE_ATTACHMENT: "Modification attachement: {{name}}",
    action_DELETE: "Supression",
    action_DELETE_ATTACHMENT: "Supression attachement: {{name}}",
    action_EXPORT_INVOICE_PDF: "Export facture pdf",
    action_EXPORT_INVOICE_EXCEL: "Export facture excel",
    action_EXPORT_INVOICES_EXCEL: "Export factures excel",
    action_EXPORT_MONTH_INVOICING_EXCEL: "Export récapitulatif factures excel",
    action_EXPORT_PAYMENTS_EXCEL: "Export paiements excel",
    action_EXPORT_MONTH_PAYMENTS_EXCEL: "Export récapitulatif paiements excel",
    last_hour: "Dernière heure",
    last_day: "Dernières 24 heures",
    last_week: "Dernière semaine",
    last_month: "Dernier mois",
    custom: "Personnalisée",
    ATTACHMENT_NOT_FOUND: "Attachement non trouvé",

    collaborator_not_allowed_to_manage_invoice: "{{collaboratorName}} n'a pas le droit de gérer les factures du client ou projet sélectionné",
    access_control: "Gestion d'accès",
    access_control_by_client: "Gestion d'accès par client",
    confirm_delete_access_control: "Êtes-vous sûr(e) de vouloir supprimer l'entrée de gestion d'accès pour {{collaboratorName}} ?",
    available_clients: "Clients disponibles",
    assigned_clients: "Clients assignés",
    available_projects: "Projets disponibles",
    assigned_projects: "Projets assignés",
    no_collaborator_is_selected: "Aucun collaborateur est sélectionné",

    attachments: "Pièces jointes",
    attachment: "Pièce jointe",
    invoiceAttachmentName: "Nom",
    invoiceAttachmentType: "Type",
    invoiceAttachmentFileType: "Type du fichier",
    add_attachment: "Ajouter une pièce jointe",
    invoice_attachment_types: "Types de pièce jointe",
    view: "Voir",
    no_status_found: "Aucun statut trouvé",

    validation_errors: "Erreurs de validation",
    invoice_form_data_required: "Le formulaire est vide",
    invoice_client_required: "Le client est obligatoire",
    invoice_label_required: "Le libellé est obligatoire",
    invoice_expected_date_required: "La date prévisionnelle est obligatoire",
    invoice_expected_settlement_date_required: "La date limite de paiement est obligatoire",
    invoice_expected_date_should_be_before_expected_settlement_date: "La date prévisionnelle doit être inférieure ou égale à la date limite de paiement",
    invoice_issue_date_should_be_before_expected_settlement_date: "la date limite de paiement ne doit pas être inférieure à la date d'émission",
    invoice_status_required: "Le statut est obligatoire",
    invoice_issue_date_required_for_emise_status: "La date d'émission est obligatoire pour le statut choisi",
    invoice_issue_date_required_for_reglee_status: "La date d'émission est obligatoire pour le statut choisi",
    invoice_settlement_date_required_for_reglee_status: "La date de réglement est obligatoire pour le statut choisi",
    invoice_issue_date_required_for_avoiree_status: "La date d'émission est obligatoire pour le statut choisi",
    invoice_settlement_date_required_for_avoiree_status: "La date de réglement est obligatoire pour le statut choisi",
    invoice_issue_date_should_not_be_after_today: "La date d'émission doit être inférieure ou égale à la date d'aujourd'hui",
    invoice_settlement_date_should_not_be_after_seven_days: "La date de règlement doit être inférieure ou égale à 7 jours après la date d'aujourd'hui",
    invoice_issue_date_should_be_before_settlement_date: "La date d'émission doit être inférieure ou égale à la date de réglement",
    invoice_order_item_description_required: "La description de l'article de commande est obligatoire",
    invoice_order_item_description_too_long: "Limite de caractères maximale dépassée pour la description de l'article de commande. Veuillez limiter votre saisie à 255 caractères",
    invoice_avoir_date_required_for_avoiree_status: "La date d'avoir est obligatoire pour le statut choisi",

    confirm_delete_attachment: "Êtes-vous sûr(e) de vouloir supprimer la pièce jointe {{attachmentName}} ?",
    attachment_preview: "Aperçu de pièce jointe",
    client_already_assigned: "Client {{name}} déjà affecté",
    parent_project_already_assigned: "Projet parent {{name}} déjà affecté",

    format_address: "Formatage de l'adresse",
    format_address_tooltip_1: "- Entrée : Retour à ligne dans un nouveau paragraphe",
    format_address_tooltip_2: "- Shift+Entrée : Retour à ligne dans le même paragraphe.",
    return: "Retour",
    groups: "Groupes",
    invoicingClients: "Clients de facturation",
    projectClients: "Clients de projets",
    none: "Aucun",

    settlement_title_one: "Règlement de la facture N° {{invoiceNumber}}",
    settlement_title_multiple: "Règlement de {{count}} factures",
    apply_expected_date_to_all_invoices: "Appliquer la date prévisionnelle à toutes les factures",
    type: "Type",
    None: "Aucune",
    Monthly: "Mensuelle",
    Annual: "Annuelle",
    recurrence: "Récurrence",
    reccurence_type: "Type de récurrence",
    create_recurrent_invoices: "Créer des factures récurrentes",
    recurrence_limit_date: "Date limite de récurrence",
    recurring_invoices_recap: "Récapitulatif des factures récurrentes créées",
    send_invoice: "Envoyer la facture N°{{invoiceNumber}}",
    sent_invoice: "Facture N°{{invoiceNumber}} envoyée le {{mailDeliveryDate}} par {{mailSender}}",
    issue_email: "Email d'emission",
    object: "Objet",
    message: "Message",
    recipients: "Destinataires",
    send_invoice_reminder_for_invoices: "Envoyer un rappel pour les factures:",
    send_as_mail: "Envoyer",
    last_reminder: "Dernier rappel",
    relance: "Relance",
    send_reminder_for_selected_invoice: "Relancer la facture sélectionnée",
    send_reminder_for_client_invoices: "Relancer toutes les factures du client",
    show: "Afficher",
    hide: "Cacher",
    email_sent: "Email envoyé",
    error_sending_email: "Erreur lors de l'envoi de l'email",
    add_emails_for: "Au moins un email pour {{recipients}} doit être ajouté",
    cannot_bulk_settle_invoices_with_more_than_one_different_currency_than_default: "Impossible de régler en masse les factures avec plus d'une devise différente de la devise par défaut",
    amount_tolerance_for_grouped_payment_search: "Tolérance du montant dans la recherche de paiements groupés",
    amount_larger_than_remaining_amount: "Montant supérieur au montant restant",
    operation_failed: "L'opération a échoué",
    invoice_payment_invoice_search_placeholder: "Recherche de factures par numéro, libellé ou montant restant TTC",
    sent_at: "Envoyée le",
    send: "Envoyer",
    reminder_email: "Email de relance",
    
    issue_email_sent: "Email d'émission envoyé",
    issue: "Émettre",
    issue_invoice: "Émettre la facture",
    avoir_of_invoice: "Avoir de la facture N° {{avoireeNumber}}",
    avoir_number: "Avoir N° {{avoireNumber}}",
    issue_mail_template_object: "Facture N° {{number}} - {{label}}",
    issue_mail_template_body: "<p>Bonjour,</p><p>Nous vous prions de trouver notre facture n° {{number}}.</p><p>Nous vous remercions pour sa prise en compte et son traitement.</p><p>Cordialement,</p>",
    issue_mail_template_body_with_order: "<p>Bonjour,</p><p>Nous vous prions de trouver notre facture n° {{number}} relative à votre commande n° {{orderNumber}}.</p><p>Nous vous remercions pour sa prise en compte et son traitement.</p><p>Cordialement,</p>",
    reminder_mail_template_object: "Relance Facture N° {{number}} - {{label}}",
    reminder_mail_template_body: "<p>Bonjour,</p><p>Sauf erreur ou omission de notre part, nous n'avons pas encore reçu le règlement de la facture n° {{number}} émise le {{issueDate}} et dont le montant s'élève à {{amountTTC}}.</p><p>Nous vous saurions gré de bien vouloir vérifier ces informations et, le cas échéant, effectuer le paiement de cette facture dans les meilleurs délais.</p><p>Nous restons à votre disposition pour toute information complémentaire.</p><p>Cordialement,</p>",
    remaining_to_pay: "restant à payer",
    avoir_date: "Date d'avoir",
    add_cc_emails: "Ajouter des emails cc",
    add_emails: "Ajouter des emails",
    emails: "Emails",
    cc_emails: "Emails cc",
    cciEmail: "Email cci",
    cc: "Cc",
    bcc: "Cci",
    year: "Année",
    export_criteria: "Critère d'export",
    responsableIds: "Ids des responsables",
    clientIds: "Ids des clients",
    invoicingEntitiesIds: "Ids des entités de facturation",
    projectIds: "Ids des projets",
    projectTypes: "Types de projets",
    afterExpectedDate: "Après la date prévisionnelle",
    beforeExpectedDate: "Avant la date prévisionnelle",
    afterExpectedSettlementDate: "Après la date limite de paiement",
    beforeExpectedSettlementDate: "Avant la date limite de paiement",
    afterIssueDate: "Après la date d'émission",
    beforeIssueDate: "Avant la date d'émission",
    afterSettlementDate: "Après la date de règlement",
    beforeSettlementDate: "Avant la date de règlement",
    NOT_FOUND: "Non trouvé"
};
