import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        display: "flex",
        marginBottom: "1%",
        width: "100%",
        boxShadow: theme.shadows[25],
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.short,
        }),
        "&:hover": {
            transform: "scale(1.008)"
        },
    },
    content: {
        cursor: "pointer",
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    month: {
        fontWeight: 500
    },
    invoiceNumber: {
        fontWeight: 500
    },
    statusChip: {
        margin: "0 4px",
        minWidth: "40px",
        borderRadius: "4px",
        border: "1px solid",
        '&:hover': {
            border: "2px solid"
        }
    },
    hiddenStatusChip: {
        display: "none"
    },
    emailBtnActions:{
        display: "flex",
        flexDirection: "column"
    }
}));
