import React, { useState, useEffect } from 'react';
import {
    Grid,
    IconButton,
    Checkbox,
    Tooltip,
    Box,
    Badge
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ListColsHeader from '../../../App/components/ListColsHeader';
import { invoiceColumns } from '../../pages/Invoicing/columns';
import { InvoiceCard } from '../InvoicingCards';
import DeleteIcon from '@material-ui/icons/Delete';
import { getInvoiceStatusColor, isEMISE, isPARTIELLEMENT_REGLEE } from '../../../utils/invoice-helper';
import { useTranslation } from 'react-i18next';
import {
    clearInvoicesPagination,
    deleteMultipleInvoices,
    setInvoicesPagination,
    setInvoicesSort
} from '../../redux/actions/invoicing';
import useStyles from './style';
import NoData from '../../../App/components/NoData';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import NotifModal from '../../../App/components/NotifModal';
import Pagination from '../../../App/components/Pagination';
import { INVOICE_STATUS } from '../../../utils/constants';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import DuplicateModal from '../../../App/components/DuplicateModal';
import SettlementDialog from '../SettlementDialog';
import { errorNotification } from '../../../utils/notification';

moment.locale("fr");

function InvoicesList({ fetch, loading, openPdfViewer, openEditInvoice, openDuplicateInvoice }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [selected, setSelected] = useState([]);
    const invoiceData = useSelector(({ invoicing }) => invoicing.invoices.list.data);
    const defaultCurrency = useSelector(({ rt }) => rt.currencies).find(currency => currency.isDefault);
    const paginationSize = useSelector(({ invoicing }) => invoicing.invoices.config.pagination.size);
    const [deleteModal, setDeleteModal] = useState(null);
    const [duplicateModal, setDuplicateModal] = useState({open:false});
    const [settlementDialogOpen, setSettlementDialogOpen] = useState(false);
    const [selectedToDelete, setSelectedToDelete] = useState([]);
    const [selectedToSettle, setSelectedToSettle] = useState([]);
    const [invoicesToSettle, setInvoicesToSettle] = useState([]);
    

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleModalChange = (open) => {
        setDeleteModal({
            ...deleteModal,
            open: open
        });
    };

    const handleDuplicateModalChange = (open) => {
        setDuplicateModal({
            ...duplicateModal,
            open,
        });
    };


    const handleSelect = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const newSelecteds = invoiceData.map((invoice) => invoice.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const deleteSelection = () => {
        dispatch(deleteMultipleInvoices(selectedToDelete)).then(res => {
            if (res.status === 200) {
                fetch();
                handleModalChange(false);
                setSelected([]);
            }
        });
    };

    const handleInvoicesDelete = () => {
        setDeleteModal({
            ...deleteModal,
            open: true,
            type: "error",
            title: t('suppression'),
            body: <span>{t('confirm_delete_multiple_invoices', { count: selectedToDelete.length })}</span>,
            showCloseButton: true,
            onChange: handleModalChange,
            cancel: {
                title: t('cancel'),
                onClick: null
            },
            confirm: {
                title: t('confirm'),
                onClick: deleteSelection
            }
        });
    };

    const openInvoicesDuplicate = () => {
        setDuplicateModal({
            ...duplicateModal,
            invoices : invoiceData.filter((invoice)=>selected.includes(invoice.id)),
            open: true,
        });
    };

    const openInvoicesSettlement = (arr) => {
        const invoicesToSettle = invoiceData.filter(invoice => arr.includes(invoice.id));
        const includedCurrencies = new Set(invoicesToSettle.map(inv => inv.currency.code));
        const hasDefault = includedCurrencies.has(defaultCurrency.code);   
        if((hasDefault && includedCurrencies.size === 2) || (includedCurrencies.size === 1)){
            setInvoicesToSettle(invoicesToSettle);
            setSettlementDialogOpen(true);
        }else{
            errorNotification(t("cannot_bulk_settle_invoices_with_more_than_one_different_currency_than_default"));
        }
        
    };

    const clearFetch = () => {
        fetch();
    };

    useEffect(() => {
        setSelectedToDelete(selected.filter(id => [INVOICE_STATUS.PREVISIONNELLE.code, INVOICE_STATUS.A_VENIR.code]
            .includes(invoiceData.find(invoice => invoice.id === id).status.code)));
        setSelectedToSettle(
            selected.filter((id) => {
                const invoice = invoiceData.find((invoice) => invoice.id === id);
                return isEMISE(invoice) || isPARTIELLEMENT_REGLEE(invoice) ;
            })
        );
    }, [selected]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item xs={10}>
                    <ListColsHeader
                        type="columns"
                        className={classes.listHeader}
                        columns={invoiceColumns}
                        onChange={clearFetch}
                        getSort={({ invoicing }) => invoicing.invoices.list.sort}
                        setSort={setInvoicesSort}
                        clearPagination={clearInvoicesPagination}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Grid container justifyContent="flex-end" alignItems="center" spacing={2} className={classes.groupedActions}>
                        <Grid item>
                            <Tooltip title={(selectedToSettle.length > 1) ? t('settle') : t('add_a_payment')}>
                                <span>
                                    {
                                        selectedToSettle.length === 0
                                            ? <IconButton disabled size="small">
                                                <DoneOutlineRoundedIcon />
                                            </IconButton>
                                            : <Badge badgeContent={selectedToSettle.length} color="primary">
                                                <IconButton onClick={() => openInvoicesSettlement(selectedToSettle)} size="small">
                                                    <DoneOutlineRoundedIcon style={{ color: INVOICE_STATUS.REGLEE.color }} />
                                                </IconButton>
                                            </Badge>
                                    }
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={t('delete')}>
                                <span>
                                    {
                                        selectedToDelete.length === 0
                                            ? <IconButton disabled size="small">
                                                <DeleteIcon />
                                            </IconButton>
                                            : <Badge badgeContent={selectedToDelete.length} color="primary">
                                                <IconButton onClick={handleInvoicesDelete} size="small">
                                                    <DeleteIcon className={classes.deleteIcon} />
                                                </IconButton>
                                            </Badge>
                                    }
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={t('duplicate')}>
                                <span>
                                    {
                                        selected.length === 0
                                            ? <IconButton disabled size="small">
                                                <ControlPointDuplicateIcon />
                                            </IconButton>
                                            : <Badge badgeContent={selected.length} color="primary">
                                                <IconButton onClick={openInvoicesDuplicate} size="small">
                                                    <ControlPointDuplicateIcon />
                                                </IconButton>
                                            </Badge>
                                    }
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Checkbox
                                indeterminate={selected.length > 0 && selected.length < invoiceData.length}
                                checked={invoiceData.length > 0 && selected.length === invoiceData.length}
                                onChange={handleSelectAll}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {
                        loading
                            ? [...Array(paginationSize)].map((_, index) => (
                                <Box mb={2} key={index}>
                                    <Skeleton height={55} variant="rect" animation="wave" className={classes.skeleton} />
                                </Box>
                            ))
                            : invoiceData.map(invoice => {
                                const isItemSelected = isSelected(invoice.id);
                                return (
                                    <InvoiceCard
                                        key={invoice.id}
                                        invoice={invoice}
                                        isItemSelected={isItemSelected}
                                        handleSelect={handleSelect}
                                        statusColor={getInvoiceStatusColor(invoice.status.code).color}
                                        onPdfClick={() => openPdfViewer(invoice)}
                                        onEditClick={() => openEditInvoice(invoice)}
                                        onDuplicateClick={() => openDuplicateInvoice(invoice)}
                                        onSettleClick={() => openInvoicesSettlement([invoice.id])}
                                        deleteModal={deleteModal}
                                        setDeleteModal={setDeleteModal}
                                        handleModalChange={handleModalChange}
                                        columns={invoiceColumns}
                                    />
                                );
                            })
                    }
                </Grid>
            </Grid>

            <NoData condition={invoiceData.length === 0} loading={loading} />

            <Pagination
                loading={loading}
                appendMode={false}
                gridView={false}
                getPagination={({ invoicing }) => invoicing.invoices.config.pagination}
                setPagination={setInvoicesPagination}
                onChange={fetch}
            />

            <NotifModal {...deleteModal} />

            {duplicateModal.open && <DuplicateModal setSelected={setSelected} onChange={handleDuplicateModalChange} {...duplicateModal} />}

            {settlementDialogOpen &&
                <SettlementDialog 
                    fetch={fetch}
                    setSelected={setSelected} 
                    invoicesToSettle={invoicesToSettle} 
                    open={settlementDialogOpen} 
                    setOpen={setSettlementDialogOpen} 
                />
            } 
        </>
    );
}

export default InvoicesList;