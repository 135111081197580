import React from 'react';
import { BREADCRUMBS, MANAGE_INVOICING_ROLE } from '../../../utils/constants';

const Dashboard = React.lazy(() => import('../../pages/Dashboard'));

export default [
    { 
        path: '/dashboard',
        exact: true, 
        name: 'dashboard', 
        component: Dashboard, 
        permissions: [], 
        private: true, 
        className:'breadcrumb-dashboard',
        roles: [MANAGE_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    }
];