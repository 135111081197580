import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    CardActions,
    Tooltip,
    IconButton,
    Checkbox,
    CircularProgress,
    Popover,
    Box,
    Button
} from '@material-ui/core';
import useStyles from './style';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import EmailIcon from '@material-ui/icons/Email';
import CreateIcon from '@material-ui/icons/Create';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import { useDispatch } from 'react-redux';
import { deleteInvoice, exportInvoice } from '../../redux/actions/invoicing';
import { downloadByteResponsePDF, downloadResponseXLSX } from '../../../utils/io';
import { enMonthsLower, INVOICE_STATUS } from '../../../utils/constants';
import { isAVOIREE, isEMISE, isREGLEE, getInvoiceDate, getInvoiceFileName, isPARTIELLEMENT_REGLEE } from '../../../utils/invoice-helper';
import { get } from 'lodash-es';
import ExcelIcon from "../../../App/assets/icons/excel.svg";
import PdfIcon from '../../../App/assets/icons/pdf.svg';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';

moment.locale("fr");

export function InvoiceCard({
    invoice,
    isItemSelected,
    handleSelect,
    statusColor,
    onPdfClick,
    onEditClick,
    onDuplicateClick,
    onSettleClick,
    deleteModal,
    setDeleteModal,
    handleModalChange,
    columns,
    width = 10,
    onSendReminderMailClick
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const [downloading, setDownloading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'export-popover' : undefined;

    const handleClick = () => {
        const invoiceDate = new Date(getInvoiceDate(invoice));
        const month = enMonthsLower[invoiceDate.getMonth()];
        history.push(`/invoicing/${invoiceDate.getFullYear()}/${month}/invoices/${invoice.id}`);
    };

    const handleDelete = () => {
        const invoiceDate = new Date(getInvoiceDate(invoice));
        const month = enMonthsLower[invoiceDate.getMonth()];
        dispatch(deleteInvoice(invoice.id)).then(res => {
            if (!res.error) {
                history.push(`/invoicing/${invoiceDate.getFullYear()}/${month}`);
            }
        });
        handleModalChange(false);
    };

    const onInvoiceDelete = () => {
        setDeleteModal({
            ...deleteModal,
            open: true,
            type: "error",
            title: t('suppression'),
            body: <span>{t('confirm_delete_invoice')}</span>,
            showCloseButton: true,
            onChange: handleModalChange,
            cancel: {
                title: t('cancel'),
                onClick: null
            },
            confirm: {
                title: t('confirm'),
                onClick: handleDelete
            }
        });
    };

    const onDownloadPdfClick = () => {
        setDownloading(true);
        setAnchorEl(null);
        dispatch(exportInvoice(invoice.id, "pdf")).then(data => {
            if (!data.error) {
                downloadByteResponsePDF(data.action.response, getInvoiceFileName(invoice));
            }
            setDownloading(false);
        });
    };

    const onDownloadXlsxClick = () => {
        setDownloading(true);
        setAnchorEl(null);
        dispatch(exportInvoice(invoice.id, "xlsx")).then(data => {
            if (!data.error) {
                downloadResponseXLSX(data.action.response, getInvoiceFileName(invoice));
            }
            setDownloading(false);
        });
    };

    const renderColumn = (col) => {
        if (col.apiName) {
            const val = get(invoice, col.apiName);
            let displayVal = val;
            if(col.formatOrElse) {
                displayVal = col.formatOrElse(val, invoice);
            } else if(val) {
                displayVal = col.format ? col.format(val, col.type === "monetary" ? invoice.currency : null) : val;
            }

            return (
                <Grid key={col.id} container item xs={col.width} alignItems="center" justifyContent="center">
                    <Grid item>
                        <Typography className={col.apiName === "number" ? classes.invoiceNumber : ""}>
                            {((col.apiName === "issueDate") && (!displayVal)) ? col.format(get(invoice, "expectedDate")) : displayVal ?? "N/A"}
                        </Typography>
                    </Grid>
                </Grid>
            );
        }

        return <i>N/A</i>;
    };

    return (

        <Card
            className={classes.root}
            elevation={1}
            style={{ borderLeft: statusColor ? `6px solid ${statusColor}` : 'none' }}
        >
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={width}>
                    <CardContent
                        title={invoice.label}
                        className={classes.content}
                        style={{ paddingBottom: "16px" }}
                        onClick={handleClick}
                    >
                        <Grid container>
                            {
                                columns.map((col, idx) => renderColumn(col, idx))
                            }
                        </Grid>
                    </CardContent>
                </Grid>
                <Grid item xs={12 - width}>
                    <CardActions>
                        <Grid container alignItems="center" justifyContent="flex-end">
                            {
                                onSettleClick &&
                                <Grid item>
                                    <Tooltip title={t('add_a_payment')} placement="top">
                                        <span>
                                            <IconButton
                                                onClick={onSettleClick}
                                                size="small"
                                                hidden={!isEMISE(invoice) && !isPARTIELLEMENT_REGLEE(invoice)}
                                            >
                                                <DoneOutlineRoundedIcon style={{ color: INVOICE_STATUS.REGLEE.color }} />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            }
                            {
                                onEditClick &&
                                <Grid item>
                                    <Tooltip title={t('edit')} placement="top">
                                        <span>
                                            <IconButton
                                                color="primary"
                                                onClick={onEditClick}
                                                size="small"
                                                hidden={isREGLEE(invoice) || isAVOIREE(invoice)}
                                            >
                                                <CreateIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            }
                            {
                                onSendReminderMailClick &&
                                <Grid item>
                                    <Tooltip title={t('relance')} placement="top">
                                        <span>
                                            <IconButton
                                                variant="contained"
                                                className={classes.printButton}
                                                onClick={() => onSendReminderMailClick(invoice, true)}
                                            >
                                                <EmailIcon size="small" />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            }
                            <Grid item>
                                <Tooltip title={t('open')} placement="top">
                                    <IconButton
                                        onClick={onPdfClick}
                                        size="small"
                                    >
                                        <PictureAsPdfIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                {
                                    downloading
                                        ? <IconButton disabled size="small">
                                            <CircularProgress disableShrink size="1rem" />
                                        </IconButton>
                                        : <Tooltip title={t('download')} placement="top" >
                                            <span>
                                                <IconButton
                                                    onClick={e => setAnchorEl(e.currentTarget)}
                                                    size="small"
                                                >
                                                    <GetAppIcon />
                                                </IconButton>
                                                <Popover
                                                    id={popoverId}
                                                    open={popoverOpen}
                                                    anchorEl={anchorEl}
                                                    onClose={() => setAnchorEl(null)}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <Box p={1}>
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            color="primary"
                                                            onClick={onDownloadPdfClick}
                                                            style={{ marginRight: "8px" }}
                                                            startIcon={<img src={PdfIcon} width={15} alt="PDF icon" />}
                                                        >
                                                            PDF
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            color="primary"
                                                            onClick={onDownloadXlsxClick}
                                                            startIcon={<img src={ExcelIcon} width={15} alt="Excel icon" />}
                                                        >
                                                            Excel
                                                        </Button>
                                                    </Box>
                                                </Popover>
                                            </span>
                                        </Tooltip>
                                }
                            </Grid>
                            {
                                onDuplicateClick &&
                                <Grid item>
                                    <Tooltip title={t('duplicate')} placement='top'>
                                        <span>
                                            <IconButton
                                                onClick={onDuplicateClick}
                                                size="small"
                                            >
                                                <ControlPointDuplicateIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            }
                            {
                                onInvoiceDelete &&
                                <Grid item>
                                    <Tooltip title={t('delete')} placement="top">
                                        <span>
                                            <IconButton
                                                onClick={onInvoiceDelete}
                                                size="small"
                                                hidden={isREGLEE(invoice) || isAVOIREE(invoice) || isEMISE(invoice) || isPARTIELLEMENT_REGLEE(invoice)}
                                            >
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            }
                            {
                                handleSelect &&
                                <Grid item>
                                    <Checkbox
                                        checked={isItemSelected}
                                        onChange={(event) => handleSelect(event, invoice.id)}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </CardActions>
                </Grid>
            </Grid>
        </Card>

    );
}