import { cloneDeep } from "lodash-es";
import { onError, onRequest, onSuccess } from "../../../../utils/http";
import { errorNotification, successNotification } from "../../../../utils/notification";
import {
    CLEAR_FINANCIAL_INVOICES_PAGINATION,
    GET_FINANCIAL_SELECTED_INVOICE,
    GET_SEARCH_FINANCIAL_INVOICES,
    GET_FINANCIAL_INVOICING_STATISTICS,
    SET_FINANCIAL_SELECTED_INVOICE,
    SET_FINANCIAL_INVOICES,
    SET_SEARCH_FINANCIAL_INVOICES,
    SET_FINANCIAL_INVOICES_PAGINATION,
    SET_FINANCIAL_INVOICING_STATISTICS,
    SET_FINANCIAL_MONTH_INVOICING,
    POST_FINANCIAL_INVOICE,
    CLEAR_FINANCIAL_SELECTED_INVOICE,
    DELETE_FINANCIAL_INVOICE,
    PUT_FINANCIAL_INVOICE,
    DELETE_MULTIPLE_FINANCIAL_INVOICES,
    DUPLICATE_MULTIPLE_FINANCIAL_INVOICES,
    EXPORT_FINANCIAL_INVOICE,
    GET_FINANCIAL_ACTIONS_AUDIT,
    GET_FINANCIAL_ACTION_CHANGE_LOG,
    SET_FINANCIAL_ACTIONS_AUDIT,
    SET_FINANCIAL_ACTIONS_AUDIT_FILTER,
    SET_FINANCIAL_ACTIONS_AUDIT_PAGINATION,
    GET_FINANCIAL_INVOICE_AUDIT,
    SET_FINANCIAL_INVOICE_AUDIT,
    CLEAR_SEARCH_FINANCIAL_INVOICES,
    SET_FINANCIAL_INVOICES_SORT,
    CLEAR_FINANCIAL_INVOICES_SORT,
    SET_FINANCIAL_INVOICING_FILTER,
    CLEAR_FINANCIAL_INVOICING_FILTER,
    EXPORT_FINANCIAL_INVOICING,
    GET_FINANCIAL_INVOICING,
    IMPORT_FINANCIAL_INVOICES,
    POST_VERIFY_DOCUMENT_SIGNATURE,
    POST_SIGN_DOCUMENT,
    UPLOAD_INVOICE_ATTACHMENT,
    GET_INVOICE_ATTACHMENTS,
    SET_INVOICE_ATTACHMENTS,
    DOWNLOAD_INVOICE_ATTACHMENT,
    PUT_INVOICE_ATTACHMENT,
    DELETE_INVOICE_ATTACHMENT,
    SETTLE_FINANCIAL_INVOICES,
    ADD_INVOICE_PAYMENT,
    GET_SEARCH_FINANCIAL_INVOICES_TO_SETTLE,
    GET_SEARCH_FINANCIAL_GROUPED_INVOICES_TO_SETTLE,
    GET_FINANCIAL_INVOICES_YEARS,
    SET_FINANCIAL_INVOICES_YEARS,
    SEND_INVOICE_TO_CLIENT,
    GET_INVOICE_ISSSUE_MAIL,
    DOWNLOAD_MAIL_ATTACHMENT,
    GET_LAST_EMITTED_INVOICE_DATE,
    GET_AVOIR_INVOICE
} from "../../constants";
import { FINANCIAL_INVOICING_ENDPOINT } from "../../constants/endpoint";
import moment from "moment";

const adjustFilter = (filter) => {
    let f = cloneDeep(filter);
    
    Object.keys(f).forEach(k => {
        if(f[k] === "" || f[k] === null || f[k] === undefined){
            delete f[k];
        }else{
            switch(k){
                case 'responsables':
                    f['responsableIds'] = f[k].map(r => r.id ?? r).join(',');
                    delete f[k];
                    break;
                case 'clients':
                    f['clientIds'] = f[k].map(c => c.id ?? c).join(',');
                    delete f[k];
                    break;
                case 'invoicingEntities':
                    f['invoicingEntitiesIds'] = f[k].map(c => c.id ?? c).join(',');
                    delete f[k];
                    break;
                case 'projects':
                    f['projectIds'] = f[k].map(p => p.id ?? p).join(',');
                    delete f[k];
                    break;
                case 'projectTypes':
                    if (f['projects'] || f['projectIds']) {
                        delete f[k];
                    } else {
                        f['projectTypes'] = f[k].map(pt => pt.name).join(',');
                    }
                    break;
                case 'status':
                    f['status'] = f[k].map(s => s.code ?? s).join(',');
                    break;
                default:
                    break;
            }
        }
    });
    return f;
};

export const getInvoicing = () => {
    return {
        type: GET_FINANCIAL_INVOICING,
        request: {
            url: FINANCIAL_INVOICING_ENDPOINT,
        },
        meta: {
            onRequest: onRequest(({ request, store }) => {
                const state = store.getState();
                const filter = state.invoicing.filter;
                const sort = state.invoicing.invoices.list.sort;
                const pagination = state.invoicing.invoices.config.pagination;
                const yearInvoicingType = state.appState.yearInvoicingType;
                
                if(filter.month || yearInvoicingType === "all"){
                    request.url = `${request.url}/invoices?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}`;
                }else{
                    request.url = `${request.url}/monthInvoicing?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}`;
                }

                request.params = adjustFilter(filter);
                return request;
            }),
            onSuccess: onSuccess(({ response, store }) => {
                const state = store.getState();
                const filterMonth = state.invoicing.filter.month;
                const yearInvoicingType = state.appState.yearInvoicingType;
                
                if(filterMonth || yearInvoicingType === "all"){
                    store.dispatch(setInvoices(response.data));
                }else{
                    store.dispatch(setMonthInvoicing(response.data));
                }

                return response;
            })
        }
    };
};

export const setInvoicingFilter = (filter) => {
    return {
        type: SET_FINANCIAL_INVOICING_FILTER,
        payload: filter
    };
};

export const clearInvoicingFilter = () => {
    return {
        type: CLEAR_FINANCIAL_INVOICING_FILTER
    };
};

export const getInvoicingStatistics = () => {
    return {
        type: GET_FINANCIAL_INVOICING_STATISTICS,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/statistics`
        },
        meta: {
            onRequest: onRequest(({ request, store }) => {
                const state = store.getState();
                const filter = state.invoicing.filter;
                
                request.params = adjustFilter(filter);
                return request;
            }),
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setInvoicingStatistics(response.data));
                return response;
            })
        }
    };
};

export const setInvoicingStatistics = (data) => {
    return {
        type: SET_FINANCIAL_INVOICING_STATISTICS,
        payload: data
    };
};

export const setMonthInvoicing = (data) => {
    return {
        type: SET_FINANCIAL_MONTH_INVOICING,
        payload: data
    };
};

export const setInvoices = (data) => {
    return {
        type: SET_FINANCIAL_INVOICES,
        payload: data
    };
};

export const getInvoicesYears = () => {
    return {
        type: GET_FINANCIAL_INVOICES_YEARS,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/statistics/invoicesYears`
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setInvoicesYears(response.data));
                return response;
            })
        }
    };
};

export const setInvoicesYears = (data) => {
    return {
        type: SET_FINANCIAL_INVOICES_YEARS,
        payload: data
    };
};

export const getSearchInvoices = (query, page) => {
    return {
        type: GET_SEARCH_FINANCIAL_INVOICES,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/search?query=${query}&page=${page}&size=6`
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setSearchInvoices(response.data));
                return response;
            })
        }
    };
};

export const getSearchInvoicesToSettle = (query) => {
    return {
        type: GET_SEARCH_FINANCIAL_INVOICES_TO_SETTLE,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/search/settle?query=${query}`
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setSearchInvoices(response.data));
                return response;
            })
        }
    };
};

export const getSearchGroupedInvoicesToSettle = (clientId, targetAmount, currencyCode) => {
    return {
        type: GET_SEARCH_FINANCIAL_GROUPED_INVOICES_TO_SETTLE,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/search/groupSettle?clientId=${clientId}&targetAmount=${targetAmount}&currencyCode=${currencyCode}`,
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                return response;
            })
        }
    };
};

export const setSearchInvoices = (data) => {
    return {
        type: SET_SEARCH_FINANCIAL_INVOICES,
        payload: data
    };
};

export const clearSearchInvoices = () => {
    return {
        type: CLEAR_SEARCH_FINANCIAL_INVOICES
    };
};

export const getSelectedInvoice = (id) => {
    return {
        type: GET_FINANCIAL_SELECTED_INVOICE,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/${id}`
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setSelectedInvoice(response.data));
                return response;
            })
        }
    };
};

export const setSelectedInvoice = (data) => {
    return {
        type: SET_FINANCIAL_SELECTED_INVOICE,
        payload: data
    };
};

export const clearSelectedInvoice = () => {
    return {
        type: CLEAR_FINANCIAL_SELECTED_INVOICE
    };
};

export const getInvoiceAudit = (invoiceId) => {
    return {
        type: GET_FINANCIAL_INVOICE_AUDIT,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoiceAudit?invoiceId=${invoiceId}`
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setInvoiceAudit(response.data));
                return response;
            })
        }
    };
};

export const getActionChangeLog = (changeLogId) => {
    return {
        type: GET_FINANCIAL_ACTION_CHANGE_LOG,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoiceAudit/actions/${changeLogId}`
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                return response;
            })
        }
    };
};

export const setInvoiceAudit = (data) => {
    return {
        type: SET_FINANCIAL_INVOICE_AUDIT,
        payload: data
    };
};

export const getActionsAudit = () => {
    return {
        type: GET_FINANCIAL_ACTIONS_AUDIT,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoiceAudit/actions`
        },
        meta: {
            onRequest: onRequest(({ request, store }) => {
                const state = store.getState();
                const filter = state.invoicing.invoices.audit.actions.filter;
                const pagination = state.invoicing.invoices.audit.actions.config.pagination;
                request.url = `${request.url}?page=${pagination.page}&size=${pagination.size}`;
                request.params = {...filter, afterDate : moment(filter.afterDate)?.format("YYYY-MM-DDTHH:mm:ss"), beforeDate: moment(filter.beforeDate)?.format("YYYY-MM-DDTHH:mm:ss"), timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone};
                return request;
            }),
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setActionsAudit(response.data));
                return response;
            })
        }
    };
};

export const setActionsAudit = (data) => {
    return {
        type: SET_FINANCIAL_ACTIONS_AUDIT,
        payload: data
    };
};

export const setActionsAuditFilter = (data) => {
    return {
        type: SET_FINANCIAL_ACTIONS_AUDIT_FILTER,
        payload: data
    };
};
export const setActionsAuditPagination = (pagination) => {
    return {
        type: SET_FINANCIAL_ACTIONS_AUDIT_PAGINATION,
        payload: pagination
    };
};

export const setInvoicesPagination = (pagination) => {
    return {
        type: SET_FINANCIAL_INVOICES_PAGINATION,
        payload: pagination
    };
};

export const clearInvoicesPagination = () => {
    return {
        type: CLEAR_FINANCIAL_INVOICES_PAGINATION
    };
};

export const postInvoice = (invoice) => {
    return {
        type: POST_FINANCIAL_INVOICE,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices`,
            method: 'post',
            data: invoice
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "create_success");
                return response;
            }),
            onError: onError(({error}) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};

export const deleteInvoice = (invoiceId) => {
    return {
        type: DELETE_FINANCIAL_INVOICE,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/${invoiceId}`,
            method: 'delete'
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "delete_success");
                return response;
            }),
            onError: onError(({error}) => {
                errorNotification('', "delete_error");
                throw error;
            })
        }
    };
};

export const putInvoice = (id, invoice) => {
    return {
        type: PUT_FINANCIAL_INVOICE,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/${id}`,
            method: 'put',
            data: invoice
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "update_success");
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "update_error");
                throw error;
            })
        }
    };
};

export const settleInvoices = (invoicesIds, settlementDate, exchangeRate) => {
    return {
        type: SETTLE_FINANCIAL_INVOICES,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/bulkSettlement`,
            method: 'put',
            data: {invoicesIds, settlementDate, exchangeRate}
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "update_success");
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "update_error");
                throw error;
            })
        }
    };
};

export const addInvoicePayment = (settlementDate,  paymentDetails, invoice) => {
    return {
        type: ADD_INVOICE_PAYMENT,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoicePayments`,
            method: 'post',
            data: {date: settlementDate, amount: Number(paymentDetails.amount.toFixed(2)), exchangeRate: paymentDetails.exchangeRate, invoice: { id: invoice.id }}
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "update_success");
                return response;
            }),
            onError: onError(({error}) => {
                if(error?.response?.data?.data?.details[0].message?.includes("expectedTotalExceedsAmountInclTax")){
                    errorNotification('', "amount_larger_than_remaining_amount");
                }else{
                    errorNotification('', "operation_failed");
                }
                throw error;
            })
        }
    };
};

export const duplicateMultipleInvoices = (invoicesIds) => {
    return {
        type: DUPLICATE_MULTIPLE_FINANCIAL_INVOICES,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/cloneInvoices`,
            method: 'post',
            data: invoicesIds
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "clone_success");
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "clone_error");
                throw error;
            })
        }
    };
};

export const deleteMultipleInvoices = (selection) => {
    return {
        type: DELETE_MULTIPLE_FINANCIAL_INVOICES,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/bulkDelete`,
            method: 'delete',
            params: { selection: selection.join(",") }
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "delete_success");
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "delete_error");
                throw error;
            })
        }
    };
};

export const exportInvoicing = () => {
    return {
        type: EXPORT_FINANCIAL_INVOICING,
        request: {
            url: FINANCIAL_INVOICING_ENDPOINT,
            responseType: 'arraybuffer'
        },
        meta: {
            onRequest: onRequest(({ request, store }) => {
                const state = store.getState();
                const filter = state.invoicing.filter;
                const yearInvoicingType = state.appState.yearInvoicingType;

                if(filter.month || yearInvoicingType === "all"){
                    request.url = `${request.url}/invoices/export`;
                    request.params = adjustFilter(filter);
                }else{
                    request.url = `${request.url}/monthInvoicing/export`;
                    request.params = adjustFilter(filter);
                }
                return request;
            }),
            onSuccess: onSuccess(({ response }) => {
                return response.data;
            })
        }
    };
};

export const exportInvoice = (invoiceId, fileType) => {
    return {
        type: EXPORT_FINANCIAL_INVOICE,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/${invoiceId}/export?fileType=${fileType}`,
            responseType: 'arraybuffer',
            method: 'get'
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                return response.data;
            })
        }
    };
};

export const importInvoices = (fileData) => {
    return {
        type: IMPORT_FINANCIAL_INVOICES,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/import`,
            method: 'post',
            data: fileData
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "create_success");
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};

export const setInvoicesSort = (sort) => {
    return {
        type: SET_FINANCIAL_INVOICES_SORT,
        payload: sort
    };
};

export const clearInvoicesSort = () => {
    return {
        type: CLEAR_FINANCIAL_INVOICES_SORT
    };
};

export const postVerifyDocumentSignature = (data) => {
    return {
        type: POST_VERIFY_DOCUMENT_SIGNATURE,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/pdf-signature/verify`,
            method: 'post',
            data: data
        }
    };
};

export const postSignDocument = (data) => {
    return {
        type: POST_SIGN_DOCUMENT,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/pdf-signature/sign`,
            method: 'post',
            data: data
        }
    };
};

export const getInvoiceAttachments = (invoiceId) => {
    return {
        type: GET_INVOICE_ATTACHMENTS,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoiceAttachments?invoiceId=${invoiceId}`
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setInvoiceAttachments(response.data));
                return response;
            })
        }
    };
};

export const setInvoiceAttachments = (data) => {
    return {
        type: SET_INVOICE_ATTACHMENTS,
        payload: data
    };
};

export const uploadInvoiceAttachment = (data) => {
    return {
        type: UPLOAD_INVOICE_ATTACHMENT,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoiceAttachments`,
            method: "post",
            data: data
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "create_success");
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};

export const downloadInvoiceAttachment = (uuid) => {
    return {
        type: DOWNLOAD_INVOICE_ATTACHMENT,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoiceAttachments/${uuid}/download`,
            responseType: 'arraybuffer'
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                return response.data;
            })
        }
    };
};

export const putInvoiceAttachment = (data) => {
    return {
        type: PUT_INVOICE_ATTACHMENT,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoiceAttachments/${data.uuid}`,
            method: "put",
            data: data
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "update_success");
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "update_error");
                throw error;
            })
        }
    };
};

export const deleteInvoiceAttachment = (uuid) => {
    return {
        type: DELETE_INVOICE_ATTACHMENT,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoiceAttachments/${uuid}`,
            method: "delete"
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "delete_success");
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "delete_error");
                throw error;
            })
        }
    };
};

export const sendInvoiceToClient = (mailData) => {
    return {
        type: SEND_INVOICE_TO_CLIENT,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/sendToClient`,
            method: 'post',
            data: mailData
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "email_sent");
                return response;
            }),
            onError: onError(({error}) => {
                errorNotification('', "error_sending_email");
                throw error;
            })
        }
    };
};

export const getInvoiceIssueMail = (invoiceId) => {
    return {
        type: GET_INVOICE_ISSSUE_MAIL,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/${invoiceId}/issueMail`,
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                return response;
            }),
            onError: onError(({error}) => {
                errorNotification('', "error_retrieving_mail");
                throw error;
            })
        }
    };
};

export const downloadMailAttachment = (attachmentId) => {
    return {
        type: DOWNLOAD_MAIL_ATTACHMENT,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/mailAttachments/${attachmentId}/download`,
            responseType: 'arraybuffer'
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                return response.data;
            }),
        }
    };
};

export const getLastEmittedInvoiceDate = (year) => {
    return {
        type: GET_LAST_EMITTED_INVOICE_DATE,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/lastEmittedDate?year=${year}`,
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                return response;
            })
        }
    };
};

export const getAvoirInvoice = (avoireeId) =>{
    return {
        type: GET_AVOIR_INVOICE,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/invoices/getAvoir?avoireeId=${avoireeId}`,
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                return response;
            })
        }
    };
};