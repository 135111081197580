import React, { useState, useRef } from 'react';
import {
    Box,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    Paper,
    Popper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { formatAmount, processFormattedAmount } from '../../../../../utils/converters';


function OrderItem({ orderItem, updateOrderItem, deleteOrderItem, currency }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const quantityTypes = {
        PERCENT: {
            id : 1,
            symbol: '%',
            text: `% : ${t('percent')}`
        },
        NUMBER: {
            id : 2,
            symbol: 'N',
            text: `N : ${t('number2')}`
        }
    };

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [quantityType, setQuantityType] = useState(orderItem.isPercent ? quantityTypes.PERCENT.id : quantityTypes.NUMBER.id);

    const handleMenuItemClick = (e, qt) => {
        updateOrderItem({
            ...orderItem,
            isPercent: qt === quantityTypes.PERCENT.id,
            quantity: qt !== quantityType
                ? qt === quantityTypes.PERCENT.id ? parseFloat(orderItem.quantity / 100) : parseFloat(orderItem.quantity * 100)
                : orderItem.quantity
        });
        setQuantityType(qt);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Box display="flex" flexDirection="row" p="12px" width={1}>
            <Box width={1}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <TextField
                            label={t('reference')}
                            defaultValue={orderItem.reference}
                            variant="outlined"
                            onChange={(e) => updateOrderItem({ ...orderItem, reference: e.target.value })}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={t("description")}
                            defaultValue={orderItem.description}
                            variant="outlined"
                            onChange={(e) => updateOrderItem({ ...orderItem, description: e.target.value })}
                            fullWidth
                            multiline
                            size="small"
                        />
                    </Grid>
                    <Grid item container xs={6} spacing={2}>
                        <Grid item xs={5}>
                            <TextField
                                className={classes.inputField}
                                label={t("quantity")}
                                defaultValue={orderItem.isPercent ? Number(orderItem.quantity * 100).toFixed(2) : Number(orderItem.quantity)}
                                type="number"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment
                                            position="end"
                                        >
                                            <div className={classes.quantitySymbol}>
                                                {Object.values(quantityTypes).find(v => v.id === quantityType).symbol}
                                            </div>
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                ref={anchorRef}
                                                onClick={handleToggle}
                                                disableRipple
                                                className={classes.quantityIconButton}
                                            >
                                                <ArrowDropDownIcon />
                                            </IconButton>
                                            <Popper
                                                open={open}
                                                anchorEl={anchorRef.current}
                                                transition
                                                disablePortal
                                                className={classes.quantityPaper}
                                                placement="bottom-end"
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList>
                                                            {Object.keys(quantityTypes).map((k, index) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    selected={quantityTypes[k].id === quantityType}
                                                                    onClick={(event) => handleMenuItemClick(event, quantityTypes[k].id)}
                                                                >
                                                                    {quantityTypes[k].text}
                                                                </MenuItem>
                                                            ))}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Popper>
                                        </InputAdornment>
                                }}
                                variant="outlined"
                                onChange={(e) => updateOrderItem({
                                    ...orderItem,
                                    quantity: orderItem.isPercent ? parseFloat((e.target.value / 100) || 0) : Number(e.target.value)
                                })}
                                fullWidth
                                size="small"
                                inputProps={{
                                    onClick: (e) => {
                                        if (orderItem.quantity === 0) e.target.value = null;
                                    },
                                    onBlur: (e) => {
                                        if (!e.target.value) e.target.value = Number(0).toFixed(2);
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className={classes.inputField}
                                label={t("unit_price")}
                                defaultValue={Number(orderItem.unitPrice).toFixed(2)}
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment classes={{ root: classes.endAdornment }} position="end">{currency && currency.symbol}</InputAdornment>,
                                }}
                                variant="outlined"
                                onChange={(e) => updateOrderItem({ ...orderItem, unitPrice: processFormattedAmount(e.target.value)})}
                                fullWidth
                                size="small"
                                inputProps={{
                                    onClick: (e) => {
                                        if (orderItem.unitPrice === 0) e.target.value = null;
                                    },
                                    onBlur: (e) => {
                                        if (!e.target.value) e.target.value = Number(0).toFixed(2);
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box mt={-0.5}>
                                <Typography variant="caption" color="textSecondary">{t("total_excl_tax")}</Typography>
                                <Typography>
                                    {formatAmount(Number(orderItem.unitPrice * orderItem.quantity), currency)} 
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <DeleteIcon
                    className={classes.deleteIcon}
                    fontSize="small"
                    onClick={() => deleteOrderItem(orderItem.displayIndex)}
                />
            </Box>
        </Box>
    );
}

export default OrderItem;