import { makeStyles } from "@material-ui/core";


export default makeStyles(() => ({
    datePicker: {
        "& input": {
            height: "33px"
        }
    },
    currencyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
}));