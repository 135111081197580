import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Typography,
    Popover,
    Box,
    CircularProgress
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../../App/components/CustomDialog';
import useStyles from './style';
import { useHistory } from 'react-router';
import InvoiceForm from '../InvoiceForm';
import PDFViewer from '../../../App/components/PDFViewer';
import { exportInvoice, postInvoice, putInvoice } from '../../redux/actions/invoicing';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { isAVOIREE, isA_VENIR, isEMISE, isPREVISIONNELLE, isREGLEE } from '../../../utils/invoice-helper';
import { INVOICE_STATUS, RECURRENT_CREATION_TYPE, enMonthsLower } from '../../../utils/constants';
import { Skeleton } from '@material-ui/lab';
import { INVOICE_TEMPLATE, isInvoiceFormSame, isInvoiceFormValidated,  } from '../../../utils/validation';
import { ImportInvoices } from '..';
import ValidationAlert from '../../../App/components/ValidationAlert';
import { getInvoicingConfig } from '../../redux/actions/parameters';
import { getAllRtCurrencies } from '../../redux/actions/rt/currency';

function InvoiceDialog({ config, setOpen, setConfig, type = "create", onCloseDialog, onSave, setMailDialogOpen }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const invoice = useSelector(({ invoicing }) => invoicing.invoices.selected);
    const [formData, setFormData] = useState(invoice);
    const [partData, setPartFormData] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [renderPopover, setRenderPopover] = useState(false);
    const filter = useSelector(({ invoicing }) => invoicing.filter);
    const taxTypes = useSelector(({ rt }) => rt.taxTypes);
    const invoicingConfig = useSelector(({ parameters }) => parameters.invoicingConfig);
    const invoiceStatusList = useSelector(({ rt }) => rt.invoiceStatus);
    const currencies = useSelector(({ rt }) => rt.currencies);
    const [saving, setSaving] = useState(false);
    
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [validationErrors, setValidationErrors] = useState(null);
    const [errorsCollapse, setErrorsCollapse] = useState(true);
    const validationAlertRef = React.useRef(null);

    const exit = useCallback(() => {
        setOpen(false);
        setValidationErrors(null);
        setErrorsCollapse(true);
        setDisabledSubmit(true);
        onCloseDialog && onCloseDialog();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const handleErrors = (errorDetails) => {
        const errors = [];
        for (const item of errorDetails) {
            const message = JSON.parse(item.message);
            if (message?.error === "issueDateBeforeLastEmittedInvoiceIssueDate") {
                errors.push(t("issueDateBeforeLastEmittedInvoiceIssueDate", {
                    number: message.data.invoice.number,
                    issueDate: moment(message.data.invoice.issueDate).format("DD/MM/yyyy"),
                }));
            }
        }
        return errors;
    }; 

    const createInvoice = () => {
        const errors = isInvoiceFormValidated(formData);
        if(!errors || errors.length === 0) {
            setSaving(true);
            dispatch(postInvoice(formData)).then(res => {
                setSaving(false);
                if (res.data) {
                    history.push(`/invoicing/${filter.year}/${enMonthsLower[new Date(res.data.createdInvoice.issueDate || res.data.createdInvoice.expectedDate).getMonth()]}/invoices/${res.data.createdInvoice.id}`, {recurringInvoices: res.data.recurringInvoices});
                } else if (res.error?.response?.data?.data?.details) {
                    setValidationErrors(handleErrors(res.error.response.data.data.details)); 
                    setErrorsCollapse(true);   
                }
            });
        } else {
            setValidationErrors(errors);
            setErrorsCollapse(true);
        }
    };

    const onSubmit = () => {
        return onSave().then(() => {
            exit();
            if(setMailDialogOpen && (config.type === "edit") && isEMISE(formData) && (invoice.status.code !== formData.status.code)){
                setMailDialogOpen(true);
            }
        });
    };

    const updateInvoice = useCallback(() => {
        const errors = isInvoiceFormValidated(formData);
        const same = config.type === "duplicate" ? false : isInvoiceFormSame(invoice, formData);
        
        if((!errors || errors.length === 0) && !same) {
            setSaving(true);
            dispatch(putInvoice(invoice.id, formData)).then(res => {
                setSaving(false);
                if (res.data) {
                    onSubmit();
                }else if (res.error?.response?.data?.data?.details) {
                    setValidationErrors(handleErrors(res.error.response.data.data.details)); 
                    setErrorsCollapse(true);                   
                }
            });
        } else {
            setValidationErrors(errors);
            setErrorsCollapse(true);
        }
    }, [exit, invoice, formData]); //eslint-disable-line react-hooks/exhaustive-deps

    const renderDialogActions = () => {
        if (!formData) return;
        const popoverOpen = Boolean(anchorEl);
        const popoverId = popoverOpen ? 'confirmation-popover' : undefined;

        if (type === "create" || type === "edit") {
            return (
                <Box className={classes.actionBtnsGroup}>
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={exit}
                        disabled={saving}
                    >
                        {t('cancel')}
                    </Button>
                    {
                        renderPopover
                            ? (
                                <div>
                                    <Button
                                        aria-describedby={popoverId}
                                        variant="contained"
                                        color="primary"
                                        onClick={e => setAnchorEl(e.currentTarget)}
                                        disabled={disabledSubmit || saving}
                                    >
                                        {t('save')}
                                    </Button>
                                    <Popover
                                        id={popoverId}
                                        open={popoverOpen}
                                        anchorEl={anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        elevation={10}
                                        className={classes.popover}
                                    >
                                        <Typography variant="subtitle2">
                                            {
                                                config.type === "edit" &&
                                                t('confirm_invoice_status_change', {
                                                    oldStatus: invoice && invoice.status && invoice.status.name,
                                                    newStatus: formData && formData.status && formData.status.name
                                                })
                                            }
                                            {
                                                config.type === "create" &&
                                                t('confirm_invoice_save', {
                                                    status: formData && formData.status && formData.status.name
                                                })
                                            }
                                        </Typography>
                                        <Box textAlign="right" pt={1}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                disableElevation
                                                onClick={() => setAnchorEl(null)}
                                                style={{ marginRight: "8px" }}
                                            >
                                                {t('no')}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                disableElevation
                                                color="primary"
                                                onClick={config.type === "edit" ? updateInvoice : createInvoice}
                                                disabled={disabledSubmit || saving}
                                            >
                                                {t('yes_save')}
                                            </Button>
                                        </Box>
                                    </Popover>
                                </div>
                            )
                            : (
                                <Button
                                    variant="contained"
                                    disableElevation
                                    color="primary"
                                    onClick={!renderPopover ? (config.type === "edit" ? updateInvoice : createInvoice) : () => { }}
                                    disabled={disabledSubmit || saving}
                                >
                                    {t('save')}
                                </Button>
                            )
                    }
                </Box>
            );
        }

        return <div></div>;
    };

    const renderDialogTitle = () => {
        if (config.type === "edit" || config.type === "pdf") {
            return (
                <Typography variant="h6">
                    {t('invoice')}
                    {
                        (isEMISE(invoice) || isREGLEE(invoice) || isAVOIREE(invoice)) &&
                        ` N°${invoice?.number}`
                    }
                </Typography>
            );
        } else if (config.type === "duplicate") {
            return (
                <Typography variant="h6"> {t('create_invoice_title')} </Typography>
            );
        } else if (config.type === "import") {
            return (
                <div>
                    <Typography variant="h6" display="inline"> {t('import_invoice_title')} {t('or').toLowerCase()} </Typography>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setConfig({ ...config, type: "create" })}
                        style={{ textTransform: "none" }}
                    >{t('create_invoice_title')}</Button>
                </div>
            );
        } else if (config.type === "create") {
            return (
                <div>
                    <Typography variant="h6" display="inline"> {t('create_invoice_title')} {t('or').toLowerCase()} </Typography>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setConfig({ ...config, type: "import" })}
                        style={{ textTransform: "none" }}
                    >{t('import_invoice_title')}</Button>
                </div>
            );
        } else {
            return <Skeleton height="2rem" width="10rem" />;
        }
    };

    const renderDialogContent = () => {
        if (config.type === "import") {
            return (
                <ImportInvoices 
                    onSubmit={onSubmit}
                />
            );
        } else if (!!formData && config.type === "create") {
            return (
                <>
                    <InvoiceForm
                        type={config.type}
                        invoice={null}
                        formData={formData}
                        setFormData={setFormData}
                        setPartFormData={setPartFormData}
                    />
                </>
            );
        } else if (!!formData && config.type === "duplicate") {
            return (
                <>
                    <InvoiceForm
                        type={config.type}
                        invoice={null}
                        formData={formData}
                        setFormData={setFormData}
                        setPartFormData={setPartFormData}
                    />
                </>
            );
        } else if (!!formData && config.type === "edit" && !!formData.id) {
            return (
                <>
                    <InvoiceForm
                        invoice={invoice}
                        formData={formData}
                        setFormData={setFormData}
                        setPartFormData={setPartFormData}
                    />
                </>
            );
        } else if (config.type === "pdf") {
            if (config.selectedInvoice) {
                return (
                    <PDFViewer
                        getPdfAction={exportInvoice}
                        pdfId={config.selectedInvoice.id}
                    />
                );
            }
        }

        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="80%">
                <CircularProgress size="3rem" thickness={4} />
            </Box>
        );
    };

    const renderErrors = () => (
        <ValidationAlert
            ref={validationAlertRef}
            collapse={setErrorsCollapse}
            collapsed={errorsCollapse}
            errors={validationErrors}
        />
    );

    const getDefaultRates = ()=>{
        return taxTypes.filter(taxType=>taxType.isRequired && taxType.taxRates.length > 0).map(taxType=>{
            return taxType.taxRates.find(rate=>rate.isDefaultValue);
        });
    };
    useEffect(() => {
        dispatch(getInvoicingConfig());
        dispatch(getAllRtCurrencies());
    }, []);//eslint-disable-line react-hooks/exhaustive-deps

    const getFilteredFormattedDate = () => {
        let date = null;
        if(filter.year) {
            if(config.month) {
                if((filter.year === new Date().getFullYear()) && (config.month === new Date().getMonth()+1)) {
                    date = moment().set({ "year": filter.year, "month": config.month - 1 }).format("yyyy-MM-DD");
                } else {
                    date = moment().set({ "year": filter.year, "month": config.month - 1 }).startOf("month").format("yyyy-MM-DD");
                }
            } else {
                if(filter.month) {
                    if((filter.year === new Date().getFullYear()) && (filter.month === new Date().getMonth()+1)) {
                        date = moment().set({ "year": filter.year, "month": filter.month - 1 }).format("yyyy-MM-DD");
                    } else {
                        date = moment().set({ "year": filter.year, "month": filter.month - 1 }).startOf("month").format("yyyy-MM-DD");
                    }
                } else {
                    if(filter.year === new Date().getFullYear()) {
                        date = moment().set({ "year": filter.year, "month": new Date().getMonth() }).startOf("month").format("yyyy-MM-DD");
                    } else {
                        date = moment().set({ "year": filter.year, "month": 0 }).startOf("month").format("yyyy-MM-DD");
                    }
                }
            }
        } else {
            date = moment().format("yyyy-MM-DD");
        }
        return date;
    };

    useEffect(() => {
        if (config.type === "edit" && !!invoice) {
            const items = invoice.invoiceOrder.orderItems.map((item, index) => ({ ...item, displayIndex: index }));
            setFormData({
                ...invoice,
                invoiceOrder: {
                    ...invoice.invoiceOrder,
                    orderItems: items
                }
            });
        } else if (config.type === "duplicate" && !!invoice) {
            const items = invoice.invoiceOrder.orderItems.map((item, index) => ({ ...item, displayIndex: index }));
            setFormData({
                ...invoice,
                id: null,
                number: null,
                expectedDate: invoice.expectedDate && moment(invoice.expectedDate).add(1, 'M'),
                expectedSettlementDate: invoice.expectedSettlementDate && moment(invoice.expectedSettlementDate).add(1, 'M'),
                issueDate: null,
                settlementDate: null,
                status: invoiceStatusList.find(s => s.code === INVOICE_STATUS.PREVISIONNELLE.code),
                invoiceOrder: {
                    ...invoice.invoiceOrder,
                    orderItems: items
                }
            });
        } else if (config.type === "create") {
            const date = getFilteredFormattedDate();
            const newFormData = cloneDeep({
                ...INVOICE_TEMPLATE,
                client: filter.client || null,
                project: filter.project || null,
                responsable: filter.responsable || null,
                expectedDate: date,
                expectedSettlementDate: date,
                status: invoiceStatusList.find(s => s.code === INVOICE_STATUS.PREVISIONNELLE.code),
                taxRates: getDefaultRates(),
                currency: currencies.find(c=>c.isDefault) || null,
                recurrentCreationType: RECURRENT_CREATION_TYPE.NONE.code,
                recurrentCreationLimit: date
            });
            setFormData(newFormData);
        } else {
            setFormData(null);
        }
    }, [invoice, config.type, config.month]); //eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {

        switch(config.type) {
            case 'edit':
                setDisabledSubmit(isInvoiceFormSame(invoice, formData));
                break;
            case 'create':
            case 'duplicate':
                setDisabledSubmit(false);
                break;
            default:
                break;
        }

    }, [formData]); //eslint-disable-line react-hooks/exhaustive-deps

    const setExpectedSettlementDate = (delay)=>{
        if(isEMISE(formData) || isREGLEE(formData) || isAVOIREE(formData)){
            setFormData({...formData, expectedSettlementDate: moment(formData.issueDate).add(delay, "days").format("yyyy-MM-DD")});
        }else if(isA_VENIR(formData) || isPREVISIONNELLE(formData)){
            setFormData({...formData, expectedSettlementDate: moment(formData.expectedDate).add(delay, "days").format("yyyy-MM-DD")});
        }
    };

    useEffect(() => {
        if(formData?.project?.paymentDelay){
            setExpectedSettlementDate(formData.project.paymentDelay);
        }else if(formData?.invoicingEntity?.paymentDelay){
            setExpectedSettlementDate(formData.invoicingEntity.paymentDelay);
        }else if(formData?.client?.paymentDelay){
            setExpectedSettlementDate(formData.client.paymentDelay);
        }else if(invoicingConfig?.paymentDelay){
            setExpectedSettlementDate(invoicingConfig.paymentDelay);
        }else {
            setExpectedSettlementDate(0);
        }
    }, [invoicingConfig?.paymentDelay, formData?.client, formData?.invoicingEntity, formData?.project, formData?.status, formData?.expectedDate, formData?.issueDate]); //eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (partData) {
            setFormData({
                ...formData,
                ...partData
            });
        }
    }, [partData]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (config.type === "create" && formData?.status && invoice?.status) {
            setRenderPopover(
                [INVOICE_STATUS.EMISE.code, INVOICE_STATUS.REGLEE.code, INVOICE_STATUS.AVOIREE.code].includes(formData.status.code)
                && invoice.status.code !== formData.status.code
            );
        } else if (config.type === "edit" && formData?.status && invoice?.status) {
            setRenderPopover(
                [INVOICE_STATUS.EMISE.code, INVOICE_STATUS.REGLEE.code, INVOICE_STATUS.AVOIREE.code].includes(formData.status.code)
                && invoice.status.code !== formData.status.code
            );
        }
    }, [invoice, formData]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(Array.isArray(validationErrors) 
            && validationErrors.length !== 0
            && validationAlertRef && validationAlertRef.current) {
            validationAlertRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [validationErrors]);

    return (
        <CustomDialog
            open={config.open ?? false}
            onClose={exit}
            title={renderDialogTitle()}
            renderContent={renderDialogContent}
            renderActions={renderDialogActions}
            className={(config.type === "pdf") ? classes.dialogPdf : classes.dialogContent}
            classes={{ paper: classes.dialogPaper }}
            saving={saving}
            disableBackdropClick={true}
            renderErrors={(Array.isArray(validationErrors) && validationErrors.length > 0) ? renderErrors : null}
        />
    );

}

export default InvoiceDialog;
