import {
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./style";
import { formatAmount } from "../../../../../utils/converters";
import { useSelector } from "react-redux";

function InvoiceFooter({ formData, setFormData }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const taxTypesList = useSelector(({rt})=> rt.taxTypes);

  const getDefaultValue = (taxType)=>{
    let taxRate = formData.taxRates.find(tr => tr.taxType.id === taxType.id) || null;
    return taxRate ? taxRate.id : 0;
  }

  const handleOnChange = (e, taxType) => {
    const taxRate = taxType.taxRates.find((tr) => tr.id === e.target.value);
    setFormData({
      ...formData,
      taxRates:  taxRate ? [...formData.taxRates.filter(rate=>rate.taxType.id !== taxType.id), taxRate] : [...formData.taxRates.filter(rate=>rate.taxType.id !== taxType.id)]
    });
  }

  useEffect(() => {
    if (formData.taxRates) {
      const taxesTotal = formData.taxRates.reduce((total, taxRate) => {
        const amountInclTax = Number(formData.amountExclTax * (1 + taxRate.value));
        return total + (amountInclTax-formData.amountExclTax);
      }, 0);
      setFormData({ ...formData,amountInclTax: formData.amountExclTax+taxesTotal });
    }
  }, [formData.invoiceOrder, formData.amountExclTax, formData.taxRates]);//eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Grid container item spacing={4} xs={8}>
          {taxTypesList.filter(type => type.taxRates.length > 0).map((taxType,idx) => (
            <Grid key={taxType.id} xs={4} item>
              <TextField
                key={taxType.id}
                className={classes.taxField}
                fullWidth
                select
                defaultValue={()=>getDefaultValue(taxType)}
                label={t(taxType.label)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      classes={{ root: classes.endAdornment }}
                      position="end"
                    >
                      %
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  handleOnChange(e, taxType);
                }}
              >
                {!taxType.isRequired && <MenuItem value={0}>
                  {t("no_tax_value")}
                  </MenuItem>}
                {taxType.taxRates.sort((a, b) => a.value < b.value).map((t) => (
                  <MenuItem value={t.id} key={t.id}>
                    {" "}
                    {Number((t.value * 100).toFixed(3))}{" "}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          container
          xs={4}
          alignItems="center"
          justifyContent="flex-end"
          spacing={10}
        >
          <Grid item>
            <Typography variant="body2">{t("amount_excl_tax")}</Typography>
            <Box pt={1} fontWeight="500">
              {formatAmount(Number(formData.amountExclTax), formData.currency)}
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="body2">{t("amount_incl_tax")}</Typography>
            <Box pt={1} fontWeight="500">
              {formatAmount(Number(formData.amountInclTax), formData.currency)}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const equalObjectId = (a, b) => a === b || (!!a && !!b && a.id === b.id);

const areEqual = (prev, next) => {
  let equalTaxRates = true;
  const prevTaxRates = prev.formData.taxRates;
  const nextTaxRates = next.formData.taxRates;
  for(let i = 0; i < prevTaxRates.length; i++){
    if(prevTaxRates.length !== nextTaxRates.length || !equalObjectId(prevTaxRates[i], nextTaxRates[i])){
      equalTaxRates= false;
      break;
    }
  }
    if (
      equalTaxRates
        && prev.formData.amountInclTax === next.formData.amountInclTax
        && prev.formData.amountExclTax === next.formData.amountExclTax
        && prev.formData.currency?.code === next.formData.currency?.code
    ) {
        return true;
    }

    return false;
};

export default React.memo(InvoiceFooter, areEqual);
