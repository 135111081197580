import React, { useEffect } from "react";
import {
    Grid,
    Paper,
    TextField,
    Button,
    Typography
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import useStyles from './style';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getInvoiceCompany, putInvoiceCompany } from "../../redux/actions/parameters";
// import BackupIcon from '@material-ui/icons/Backup';

const textFields = ["name", "address", "postalCode", "city", "country", "siren", "email", "iban", "bic", "bankName"];

function CompanyInformation() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const invoiceCompany = useSelector(({ parameters }) => parameters.invoiceCompany);
    const { register, handleSubmit, formState, reset } = useForm();

    const onSubmit = (data) => {
        dispatch(putInvoiceCompany(data));
    };

    useEffect(() => {
        dispatch(getInvoiceCompany());
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        reset(invoiceCompany, { keepDirty: false });
    }, [invoiceCompany]); //eslint-disable-line react-hooks/exhaustive-deps

    return (

        <Paper className={classes.paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t('invoice_company')}</Typography>
                    </Grid>
                    {
                        textFields.map((field, idx) =>
                            <Grid item xs={(((textFields.length % 2) !== 0) && (textFields.length - 1 === idx)) ?  12 : 6} key={field}>
                                <TextField
                                    label={t(field)}
                                    defaultValue=" "
                                    variant="outlined"
                                    fullWidth
                                    {...register(field, { required: true })}
                                    error={formState.errors[field]}
                                />
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <TextField
                            label={t("tvaNumber")}
                            defaultValue=" "
                            variant="outlined"
                            fullWidth
                            {...register("tvaNumber", { required: false })}
                            error={formState.errors["tvaNumber"]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('bankingInfoBloc')}
                            defaultValue=" "
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            {...register("bankingInfoBloc", { required: false })}
                            error={formState.errors["bankingInfoBloc"]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('footerMessage')}
                            defaultValue=" "
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            {...register("footerMessage", { required: true })}
                            error={formState.errors["footerMessage"]}
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Button
                            variant="contained"
                            component="label"
                            startIcon={<BackupIcon />}
                            style={{height: "35px", textTransform: "none"}}
                        >
                            {t("upload_stamp")}
                            <input
                                type="file"
                                onChange={() => {}}
                                {...register("stamp", {
                                    required: false,
                                    validate: {
                                        maxSize: files => files[0]?.size < 5000000 || 'Max 5MB',
                                        acceptedFormats: files => 
                                            ['image/jpeg', 'image/png', 'image/gif'].includes(files[0]?.type) || 'Only PNG, JPEG e GIF',
                                    } 
                                })}
                                hidden
                            />
                        </Button>
                    </Grid> */}
                    <Grid container item xs={12} justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disableElevation
                            disabled={!formState.isValid || !formState.isDirty}
                        >{t('save')}</Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>

    );

}

export default CompanyInformation;