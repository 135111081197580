import { Box, Grid, Link, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ListColsHeader from '../../../App/components/ListColsHeader';
import NoData from '../../../App/components/NoData';
import Pagination from '../../../App/components/Pagination';
import { invoiceColumns } from '../../pages/Dashboard/columns';
import { clearDashboardInvoicesPagination, setDashboardInvoicesPagination, setDashboardInvoicesSort } from '../../redux/actions/dashboard';
import { setInvoicingFilter } from '../../redux/actions/invoicing';
import { InvoiceCard } from '../InvoicingCards';
import useStyles from './style';

function DashboardInvoicesList({ fetch, loading, openPdfViewer, setSelectedReminderInvoices, setMailDialogOpen }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const invoiceData = useSelector(({ dashboard }) => dashboard.invoices.list.data);
    const paginationSize = useSelector(({ dashboard }) => dashboard.invoices.config.pagination.size);
    const filter = useSelector(({ dashboard }) => dashboard.invoices.filter);
    const years = useMemo(() => 
        [...Array(moment(filter.beforeIssueDate).subtract(1, "days").year() - moment(filter.afterIssueDate).add(1, "days").year() + 1).keys()].map(i => moment(filter.afterIssueDate).add(1, "days").year() + i) 
    , [loading]); //eslint-disable-line react-hooks/exhaustive-deps

    const dispatchFilter = (f) => dispatch(setInvoicingFilter(f)); //eslint-disable-line react-hooks/exhaustive-deps

    const goToInvoicingArea = (year) => {
        dispatchFilter({
            clients: [],
            projectTypes: [],
            projects: [],
            status: filter.status,
            beforeIssueDate: filter.beforeIssueDate,
            afterIssueDate: filter.afterIssueDate,
            beforeExpectedSettlementDate: filter.beforeExpectedSettlementDate
        });

        history.push(`/invoicing/${year}/invoices`);
    };

    const clearFetch = () => {
        dispatch(clearDashboardInvoicesPagination());
        fetch();
    };

    const onSendReminderMailClick = (invoice, isSelectedInvoiceOnly) => {
        if(!isSelectedInvoiceOnly){
            const filteredInvoices = invoiceData.filter((inv)=> inv.client.id === invoice.client.id);
            setSelectedReminderInvoices([...filteredInvoices]);
        }else{
            setSelectedReminderInvoices([invoice]);
        }
        setMailDialogOpen(true);
    };

    return (
        <Paper className={classes.invoicesPaper}>
            <Grid container justifyContent="space-between" alignItems="flex-start">
                <Grid item xs>
                    <Typography color="textSecondary" className={classes.title}>
                        {t('late_invoices_awaiting_payment')}
                    </Typography>
                </Grid>
                <Grid container item xs justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography color="textSecondary" className={classes.subtitle}>
                            {t('go_to_invoicing_area')}:
                        </Typography>
                    </Grid>
                    {
                        years.map(year => (
                            <Grid item key={year}>
                                <Link 
                                    href={`/invoicing/${year}/invoices`}
                                    onClick={(e) => {e.preventDefault(); goToInvoicingArea(year);}}
                                >
                                    {year}
                                </Link>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
            <Grid container>
                <Grid container justifyContent="flex-start" alignItems="center">
                    <Grid item xs={10}>
                        <ListColsHeader
                            type="columns"
                            className={classes.listHeader}
                            columns={invoiceColumns}
                            onChange={clearFetch}
                            getSort={({ dashboard }) => dashboard.invoices.list.sort}
                            setSort={setDashboardInvoicesSort}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12}>
                        {
                            !invoiceData || loading
                                ? [...Array(paginationSize)].map((_, index) => (
                                    <Box mb={2} key={index}>
                                        <Skeleton height={55} variant="rect" animation="wave" className={classes.skeleton} />
                                    </Box>
                                ))
                                : invoiceData.map(invoice => {
                                    return (
                                        <InvoiceCard
                                            key={invoice.id}
                                            invoice={invoice}
                                            onPdfClick={() => openPdfViewer(invoice)}
                                            onSendReminderMailClick={onSendReminderMailClick}
                                            columns={invoiceColumns}
                                            width={10}
                                        />
                                    );
                                })
                        }
                    </Grid>
                </Grid>

                {invoiceData && <NoData condition={invoiceData.length === 0} loading={loading} />}

                <Pagination
                    loading={loading}
                    appendMode={false}
                    gridView={false}
                    getPagination={({ dashboard }) => dashboard.invoices.config.pagination}
                    setPagination={setDashboardInvoicesPagination}
                    onChange={fetch}
                />
            </Grid>
        </Paper>
    );
}

export default DashboardInvoicesList;
