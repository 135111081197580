import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardActions,
    Grid,
    Typography,
    IconButton,
    TextField,
    Box,
    Tooltip,
    Checkbox,
    Radio,
    InputAdornment,
    MenuItem,
    Collapse,
    Chip
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './style';
import { useDispatch, useSelector } from "react-redux";
import { adjustDefaultValues, getColorFromPercentage } from '../../../../utils/converters';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { get } from "lodash-es";
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import TextEditorAction from "./TextEditorAction";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Autocomplete } from "@material-ui/lab";


function RtCard({ item, rtable, actionModal, setActionModal, displayModal, instances, setInstances, resetInstances, newInstance, setNewInstance }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [instance, setInstance] = useState(item);
    const [errors, setErrors] = useState(rtable.columns.reduce((obj, col) => ({ ...obj, [col.id]: false }), {}));
    const [disabled, setDisabled] = useState(false);
    const openEdit = useCallback(() => setEdit(true), []);
    const [editorState, setEditorState] = useState(null);
    const [customAction, setCustomAction] = useState(null);
    const [collapseOpen, setCollapseOpen] = useState(false);
    const rtGlobalList = useSelector(({ rt }) => rt);

    const cancelEdit = () => {
        setEdit(false);
        resetInstances();
        setInstance(item);
    };

    const saveEdit = () => {
        if (rtable.columns.some(col => col.type === "radio")) {
            const id = instances.findIndex(inst => inst.id === instance.id);
            if (id > -1) instances[id] = instance;
            instances.forEach(inst => {
                dispatch(rtable.put(inst.id, adjustDefaultValues(rtable, inst)));
            });
        } else {
            dispatch(rtable.put(instance.id, instance));
        }
        setEdit(false);
    };

    const handleDelete = () => {
        setActionModal({
            ...actionModal,
            open: true,
            type: "error",
            title: t('suppression'),
            body: <span>
                {t('confirm_delete')} <b>{t(rtable.name)} {t(rtable.columns[0].transform
                    ? rtable.columns[0].transform(get(instance, rtable.columns[0].apiName))
                    : get(instance, rtable.columns[0].apiName))}{rtable.columns[0].endAdornment ?? null}</b> ?
            </span>,
            showCloseButton: true,
            onChange: displayModal,
            cancel: {
                title: t('cancel'),
                onClick: null
            },
            confirm: {
                title: t('confirm'),
                onClick: () => {
                    dispatch(rtable.delete(instance.id));
                    displayModal(false);
                }
            }
        });
    };

    const onRadioChange = (e, col) => {
        if (e.target.checked && !!newInstance) {
            setNewInstance({ ...newInstance, [col.apiName]: false });
        }
        setInstances([...instances].map(i => ({ ...i, [col.apiName]: i.id === instance.id ? e.target.checked : (e.target.checked ? !(e.target.checked) : i[col.apiName]) })));
    };

    const renderColumn = (col) => {
        switch (col.type) {
            case "boolean":
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Checkbox
                            checked={instance[col.apiName]}
                            color="primary"
                            onChange={e => {
                                setInstance({ ...instance, [col.apiName]: e.target.checked });
                            }}
                        />
                    );
                }

                return (
                    <Typography style={col.style}> {get(instance, col.apiName) ? "Oui" : "Non"} </Typography>
                );
            case "radio":
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Radio
                            checked={instance[col.apiName]}
                            color="primary"
                            onChange={e => onRadioChange(e, col)}
                        />
                    );
                }

                return (
                    get(instance, col.apiName) && <CheckCircleIcon color="primary" fontSize="small" />
                );
            case "number":
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Box mr={1}>
                            <TextField
                                type="number"
                                defaultValue={col.transform ? col.transform(instance[col.apiName]) : instance[col.apiName]}
                                variant="outlined"
                                size="small"
                                placeholder={t(col.name)}
                                fullWidth
                                error={errors[col.id]}
                                onChange={e => {
                                    const val = e.target.value;
                                    setInstance({ ...instance, [col.apiName]: val === "" ? null : col.reverseTransform ? col.reverseTransform(val) : val });
                                    !col.optional && setErrors({ ...errors, [col.id]: val === "" || col.validated ? !col.validated(val) : false });
                                }}
                                {...(col.inputProps
                                    ? {
                                        inputProps: col.inputProps
                                    }
                                    : {})}
                                {...(col.endAdornment
                                    ? {
                                        InputProps: {
                                            endAdornment: <InputAdornment classes={{ root: classes.endAdornment }} position="end">{col.endAdornment}</InputAdornment>,
                                        }
                                    }
                                    : {})}
                            />
                              
                        </Box>
                    );
                }

                return (
                    <Typography style={col.style}> {get(instance, col.apiName) == null ? "N/A" : col.transform ? col.transform(get(instance, col.apiName)) : get(instance, col.apiName)}{col.endAdornment ?? null} </Typography>
                );
            case "select":
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Box mr={1}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                value={get(instance, `${col.apiBase}.id`) || ""}
                                error={errors[col.id]}
                                size="small"
                                placeholder={t(col.name)}
                                {...(col.inputProps
                                    ? {
                                        inputProps: col.inputProps
                                    }
                                    : {})}
                                onChange={e => {
                                    setInstance({ ...instance, [col.apiBase]: rtGlobalList[col.config.storeName].find(x => x.id === e.target.value) || null });
                                    !col.optional && setErrors({ ...errors, [col.id]: !e.target.value });
                                }}
                                style={{ width: "100%" }}
                            >
                                {col.optional && <MenuItem value="">{t("none")}</MenuItem>}
                                {rtGlobalList[col.config.storeName].map((item, idx) => <MenuItem key={idx} value={item.id} style={{ width: "100%" }}>{get(item, col.config.apiName)}</MenuItem>)}
                            </TextField>
                        </Box>
                    );
                }

                return (
                    <Typography style={col.style}> {get(instance, col.apiName)} </Typography>
                );
            case "textList":
                if((rtable.editable || col.editable) && edit){
                    return (
                        <Box mr={1}>
                            <Autocomplete
                                options={[]}
                                freeSolo
                                multiple
                                renderTags={(value, props) =>
                                    value.map((option, index) => (
                                        <Chip 
                                            label={option} 
                                            style={{backgroundColor: col.validated && col.validated(option) ? "" : "#ff000054"}}
                                            {...props({ index })} 
                                        />
                                    ))
                                }
                                renderInput={
                                    (params) => 
                                        <TextField
                                            error={errors[col.id]}
                                            variant="outlined" 
                                            label={t("add_" + col.name)} {...params} 
                                        />}
                                value={instance[col.apiName] || []}
                                variant="outlined"
                                size="small"
                                placeholder={t(col.name)}
                                fullWidth
                                {...(col.inputProps
                                    ? {
                                        inputProps: col.inputProps
                                    }
                                    : {})}
                                onChange={(e, val) => {
                                    setInstance({ ...instance, [col.apiName]: val });
                                    !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" });
                                    if(col.validated){
                                        let hasInvalidItem = false;
                                        for(const item of val){
                                            if(!col.validated(item)){
                                                hasInvalidItem = true;
                                                break;
                                            }
                                        }
                                        setErrors({ ...errors, [col.id]: hasInvalidItem});
                                    }
                                }}
                            />
                        </Box>
                    );
                }
                return (
                    <>
                        {get(instance, col.apiName) && get(instance, col.apiName).map(val => (
                            <Chip
                                key={val}
                                label={val}
                                style={{ margin: '5px' }}
                            />
                        ))} 
                    </>);
            default:
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Box mr={1}>
                            <TextField
                                type="text"
                                defaultValue={instance[col.apiName]}
                                variant="outlined"
                                size="small"
                                placeholder={t(col.name)}
                                fullWidth
                                error={errors[col.id]}
                                {...(col.inputProps
                                    ? {
                                        inputProps: col.inputProps
                                    }
                                    : {})}
                                onChange={e => {
                                    const val = e.target.value;
                                    setInstance({ ...instance, [col.apiName]: val.trim() || null });
                                    !col.optional && setErrors({ ...errors, [col.id]: val === "" });
                                    col.validated && setErrors({ ...errors, [col.id]: !col.validated(val)});
                                }}
                            />
                        </Box>
                    );
                }

                return (
                    <Typography style={col.style}> {get(instance, col.apiName)} </Typography>
                );
        }
    };

    const closeCustomActionModal = (display) => {
        displayModal(display);
        !display && setCustomAction(null);
    };

    const hasCollapsedColumns = () => {
        return rtable.columns.some(col => col.collapse);
    };

    const toggleCollapse = () => {
        setCollapseOpen(prev => !prev);
    };

    const renderCustomActions = (actions) => {
        return actions.map((action, idx) => {
            switch (action.type) {
                case "textEditor":
                    return (
                        <Grid key={idx} item>
                            <Tooltip title={t(action.name)} placement="top">
                                <IconButton onClick={() => setCustomAction(action.type)}>
                                    <FormatAlignCenterIcon />
                                </IconButton>
                            </Tooltip>
                            {
                                customAction === action.type &&
                                <TextEditorAction
                                    actionModal={actionModal}
                                    setActionModal={setActionModal}
                                    displayModal={closeCustomActionModal}
                                    editorState={editorState}
                                    setEditorState={setEditorState}
                                    instance={instance}
                                    action={action}
                                />
                            }
                        </Grid>
                    );
                default:
                    return null;
            }
        });
    };

    useEffect(() => {
        setInstance(item);
    }, [item]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDisabled(!Object.values(errors).every(e => e === false));
    }, [errors]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (edit) setCollapseOpen(true);
    }, [edit]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card
            style={
                rtable.hasColor
                    ? {
                        borderLeft: `5px solid ${getColorFromPercentage(
                            instance.orderPercent
                        )}`,
                    }
                    : {}
            }
            className={classes.root}
            elevation={1}
        >
            <Grid container alignItems="center" justifyContent="center">
                <Grid
                    item
                    xs={
                        rtable.editable ||
              rtable.columns.filter((col) => col.editable).length > 0
                            ? 10
                            : 12
                    }
                >
                    <CardContent
                        className={classes.content}
                        style={{ paddingBottom: "16px" }}
                    >
                        {hasCollapsedColumns() && (
                            <Box mr={1}>
                                <IconButton
                                    onClick={toggleCollapse}
                                    size="small"
                                    className={classes.collapseButton}
                                >
                                    {collapseOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Box>
                        )}
                        <Grid container>
                            {rtable.columns
                                .filter((col) => !col.collapse)
                                .map((col) => (
                                    <Grid
                                        container
                                        item
                                        xs={col.width}
                                        key={col.id}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item xs={col.fullWidth ? 12 : undefined}>
                                            <Box textAlign="center">{renderColumn(col)}</Box>
                                        </Grid>
                                    </Grid>
                                ))}
                            {hasCollapsedColumns() && (
                                <Grid item xs={12}>
                                    <Collapse in={collapseOpen}>
                                        <Box className={classes.collapse}>
                                            {rtable.columns
                                                .filter((col) => col.collapse)
                                                .map((col) => (
                                                    <Grid key={col.id} container alignItems="center" spacing={2}>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                variant="overline"
                                                                className={classes.colName}
                                                            >
                                                                {t(col.name)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            {renderColumn(col)}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                        </Box>
                                    </Collapse>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Grid>
                {(rtable.editable || rtable.actions) && (
                    <Grid item xs={2}>
                        <CardActions>
                            <Grid container alignItems="center" justifyContent="center">
                                {rtable.actions && renderCustomActions(rtable.actions)}
                                {rtable.editable && (
                                    <>
                                        <Grid item>
                                            <Tooltip
                                                title={edit ? t("save_quick_edit") : t("edit")}
                                                placement="top"
                                            >
                                                {edit ? (
                                                    <IconButton
                                                        color="primary"
                                                        onClick={saveEdit}
                                                        disabled={disabled}
                                                    >
                                                        <DoneIcon />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton onClick={openEdit}>
                                                        <EditIcon />
                                                    </IconButton>
                                                )}
                                            </Tooltip>
                                        </Grid>
                                        {edit && (
                                            <Grid item>
                                                <Tooltip title={t("cancel")} placement="top">
                                                    <IconButton onClick={cancelEdit}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Tooltip title={t("delete")} placement="top">
                                                <IconButton onClick={handleDelete}>
                                                    <DeleteIcon className={classes.deleteIcon} />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </CardActions>
                    </Grid>
                )}
                {rtable.columns.filter((col) => col.editable).length > 0 && (
                    <Grid item xs={2}>
                        <CardActions>
                            <Grid container alignItems="center" justifyContent="center">
                                {rtable.actions && renderCustomActions(rtable.actions)}
                                <Grid item>
                                    <Tooltip
                                        title={edit ? t("save_quick_edit") : t("edit")}
                                        placement="top"
                                    >
                                        {edit ? (
                                            <IconButton
                                                color="primary"
                                                onClick={saveEdit}
                                                disabled={disabled}
                                            >
                                                <DoneIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton onClick={openEdit}>
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                    </Tooltip>
                                </Grid>
                                {edit && (
                                    <Grid item>
                                        <Tooltip title={t("cancel")} placement="top">
                                            <IconButton onClick={cancelEdit}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                )}
                    
                            </Grid>
                        </CardActions>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
}

export default RtCard;
