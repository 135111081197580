import React, { useEffect, useMemo, useState } from 'react';
import {
    Grid,
    Tabs,
    Tab,
    Paper,
    Box,
    Button,
    TabScrollButton,
    TablePagination,
    OutlinedInput
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import { RtablesDef } from '../rtablesDef';
import ListColsHeader from '../../../../App/components/ListColsHeader';
import { getGlobalIndicators } from '../../../redux/actions/indicators';
import RtCard from '../RtCard';
import RtCreateCard from '../RtCreateCard';
import { Skeleton } from '@material-ui/lab';
import NoData from '../../../../App/components/NoData';
import { getInitialPagination } from '../../../../utils/common-state';
import SearchIcon from '@material-ui/icons/Search';
import {get} from 'lodash-es';
import CustomTabPanel from '../CustomTabPanel';

var timeout = 0;

function RtGroup({group, setGroup, actionModal, setActionModal, displayModal}) {
    const requestedRtable = RtablesDef.find(rt => rt.path === window.location.pathname);
    const groupedRtablesDef = useMemo(() => RtablesDef.filter(rt => !group || rt.group === group), [group]); //eslint-disable-line react-hooks/exhaustive-deps

    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const rtGlobalList = useSelector(({rt}) => rt);
    const [activeTab, setActiveTab] = useState(requestedRtable?.id ?? groupedRtablesDef[0]?.id);
    const [activeRtable, setActiveRtable] = useState(RtablesDef[requestedRtable?.id ?? groupedRtablesDef[0]?.id]);
    const [create, setCreate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [instances, setInstances] = useState([]);
    const [newInstance, setNewInstance] = useState();
    const [pagination, setPagination] = useState(getInitialPagination(false));
    const [searchText, setSearchText] = useState();

    const CustomTabScrollButton = (props) => {
        return (
            <TabScrollButton
                {...props}
                className={classes.scrollButton}
            />
        );
    };

    const changeActiveTab = (event, value) => {
        setInstances([]);
        setActiveTab(groupedRtablesDef[value].id);
        setCreate(false);
        setPagination(getInitialPagination(false));
    };

    const resetInstances = () => {
        if(searchText) {
            searchByText(searchText);
        } else {
            setInstances(rtGlobalList[activeRtable.storeName]);
        }
    };

    const openCreateForm = () => {
        setCreate(true);
        setNewInstance(activeRtable.columns.reduce((obj, col) => ({ ...obj, [col.apiName]: ["radio", "boolean"].includes(col.type) ? false : "" }), {}));
    };

    const searchByText = () => {
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {
            setPagination(getInitialPagination(false));
            if(!searchText || searchText.length === 0) {
                resetInstances();
                return;
            }

            const searchableCols = activeRtable.columns.filter(col => col.searchable);
            setInstances(
                rtGlobalList[activeRtable.storeName]
                    .filter(item => searchableCols.some(col => {
                        const val = get(item, col.apiName);
                        const transformedVal = col.transform ? col.transform(val) : val;
                        return transformedVal && transformedVal.toString().toLowerCase().includes(searchText.toLowerCase());
                    }))
            );
        }, 250);
    };

    useEffect(() => {
        dispatch(getGlobalIndicators());
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSearchText(null);
        const temp = RtablesDef.find(rt => rt.id === activeTab);
        setActiveRtable(temp);
        setLoading(true);
        temp.columns.forEach(col => col.config && col.config.listAll && dispatch(col.config.listAll()));
        dispatch(temp.listAll())
            .then((response) => { 
                if(response.error || !response.data) {
                    setInstances([]);
                } else {
                    setInstances(response.data);
                }
                setLoading(false); 
            })
            .catch(() => {
                setLoading(false);
                setInstances([]);
            });
        window.history.replaceState(null, 'Rtable', temp.path);
    }, [activeTab]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        resetInstances();
    }, [rtGlobalList, searchText]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(requestedRtable?.group && group) {
            setGroup(requestedRtable.group);
        }
    }, [requestedRtable]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(group && requestedRtable?.group !== group) {
            setActiveTab(groupedRtablesDef[0].id);
        }
    }, [groupedRtablesDef]); //eslint-disable-line react-hooks/exhaustive-deps

    return groupedRtablesDef.findIndex(item => item.id === activeTab) > -1 && (
        <Grid container>
            <Grid item xs={12}>
                <Paper className={classes.tabsContainer}>
                    <Tabs
                        value={groupedRtablesDef.findIndex(item => item.id === activeTab)}
                        onChange={changeActiveTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        ScrollButtonComponent={CustomTabScrollButton}
                    >
                        {groupedRtablesDef.map(rt => <Tab label={t(rt.name)} style={{ textTransform: "none" }} key={rt.id}></Tab>)}
                    </Tabs>
                </Paper>
                {activeRtable.custom ? <CustomTabPanel {...{displayModal, actionModal, setActionModal, loading, instances, rTable:activeRtable}} />:<>
                <Box role="tabpanel" mt={4}>
                    <Grid container alignItems="flex-start">
                        <Grid container item xs={12} alignItems="center" justifyContent={activeRtable.searchable ? "space-between" : "flex-end"}>
                            {
                                activeRtable.searchable &&
                                <OutlinedInput
                                    defaultValue={searchText}
                                    margin="dense"
                                    placeholder={t("search")}
                                    className={classes.searchInput}
                                    startAdornment={<SearchIcon color="primary" style={{ marginRight: "8px" }} />}
                                    onChange={(e) => setSearchText(e.target.value === "" ? null : e.target.value)}
                                />
                            }
                            {
                                activeRtable.editable &&
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.addButton}
                                    startIcon={<AddIcon />}
                                    onClick={openCreateForm}
                                    hidden={create}
                                >
                                    {t('add')}
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={(activeRtable.editable || (activeRtable.columns.filter(col=>col.editable).length > 0)) ? 10 : 12}>
                            <ListColsHeader
                                type="columns"
                                className={classes.tableHeader}
                                columns={activeRtable.columns.filter(col => !col.collapse)}
                            />
                        </Grid>
                    </Grid>

                    <NoData condition={instances.length === 0 && !create} loading={loading} />

                    {
                        create && !loading
                            ? (
                                <RtCreateCard
                                    rtable={activeRtable}
                                    setCreate={setCreate}
                                    instances={instances}
                                    setInstances={setInstances}
                                    resetInstances={resetInstances}
                                    instance={newInstance}
                                    setInstance={setNewInstance}
                                />
                            )
                            : <></>
                    }{
                        loading
                            ? [...Array(3)].map((_, index) => (
                                <Box key={index}>
                                    <Skeleton height={62.5} variant="rect" animation="wave" className={classes.skeleton} />
                                </Box>
                            ))
                            : instances && 
                            instances.slice(
                                activeRtable.pageable ? pagination.page * pagination.size : 0, 
                                activeRtable.pageable ? (pagination.page+1)*pagination.size : instances.length
                            ).map((item, index) =>
                                <RtCard
                                    key={index}
                                    item={item}
                                    items={rtGlobalList[activeRtable.storeName]}
                                    rtable={activeRtable}
                                    actionModal={actionModal}
                                    setActionModal={setActionModal}
                                    displayModal={displayModal}
                                    instances={instances}
                                    setInstances={setInstances}
                                    resetInstances={resetInstances}
                                    newInstance={newInstance}
                                    setNewInstance={setNewInstance}
                                />
                            )
                    }
                </Box>
                </>}
                {
                    activeRtable.pageable && 
                    <Grid container item xs={12} justifyContent="flex-end">
                        <TablePagination
                            rowsPerPageOptions={[5, 15, 30, 50]}
                            component="div"
                            count={instances?.length ?? 0}
                            rowsPerPage={pagination.size}
                            page={pagination.page}
                            onPageChange={(event, newPage) => setPagination({...pagination, page: newPage})}
                            onRowsPerPageChange={(event) => setPagination({...pagination, page: 0, size: parseInt(event.target.value, 10)})}
                        />
                    </Grid>
                }
            </Grid>
        </Grid>
    );
}

export default RtGroup;